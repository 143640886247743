import axios from 'axios'
import {
  BaseRunningReasonOptions,
  MovementReasons,
  PlayTypeOptions,
  PlayTypes,
} from 'constants'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { action, computed, observable, reaction, toJS } from 'mobx'
import moment from 'moment'

const CancelToken = axios.CancelToken

let source = CancelToken.source()
const fetch = (options) =>
  axios(
    Object.assign({}, options, {
      cancelToken: source.token,
    })
  ).then((response) => response.data)

export default class GameStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  POLLING_INTERVAL = 15000

  cancel() {
    source.cancel()
    source = CancelToken.source()
  }

  @computed get isLiveMode() {
    return (this.int && true) || false
  }

  @action exitLiveMode() {
    this.cancel()
    this.clearInt()
  }

  @action enterLiveMode() {
    this.update()
    this.setInt()
  }

  @action toggleLiveMode() {
    if (this.isLiveMode) {
      this.exitLiveMode()
    } else {
      this.enterLiveMode()
    }
  }

  @computed get isFinal() {
    return this.stringerData.isFinal || false
  }

  @computed get isFlexibleRules() {
    return this.stringerData.isFlexibleRules || false
  }

  @computed get stringerUsername() {
    let { schedule = {} } = this

    let { stringerStatus = {} } = schedule

    let { stringerUsername = null } = stringerStatus

    return stringerUsername
  }

  @computed get isTracking() {
    return this.schedule.isTracking || false
  }

  @computed get isConnected() {
    return this.stringerUsername ? true : false
  }

  @observable _isLocked = false

  @action lock() {
    this._isLocked = true
  }

  @action unlock() {
    this._isLocked = false
  }

  @computed get __isLocked() {
    return (this.isConnected && this.isFinal) || false
  }

  @computed get isLocked() {
    let { schedule = {} } = this

    let { stringerStatus = {} } = schedule

    let { stringerUsername, status } = stringerStatus

    if (['dbe', 'dfr'].includes(stringerUsername) && status == 'Complete') {
      return false
    }

    return this.__isLocked || this._isLocked || false
  }

  fetch() {
    const id = this.store.gamePk ? this.store.gamePk : this.store.eventId
    const url = '/api/games/' + id + '?pageType=' + this.page
    return fetch({
      url,
    })
  }

  @observable isGameLocked = true

  @action clearGameState() {
    if (!this.store.auth.hasClearGameStatsPermission) {
      return
    }

    this.isLoading = true
    return fetch({
      method: 'DELETE',
      url: `/api/games/${this.store.gamePk}/clearGameState`,
    })
      .then(
        action(() => {
          this.reset()
        })
      )
      .catch((err) => {
        console.error(err)
      })
      .finally(
        action(() => {
          this.isLoading = false
        })
      )
  }

  @action loadLiveFeedDataToRedis() {
    if (!this.store.auth.hasReloadGameRedisPermission) {
      return
    }

    this.isLoading = true
    return fetch({
      method: 'POST',
      url: `/api/games/${this.store.gamePk}/loadLiveFeedDataToRedis`,
    })
      .then(
        action(() => {
          this.reset()
        })
      )
      .catch((err) => {
        console.error(err)
      })
      .finally(
        action(() => {
          this.isLoading = false
        })
      )
  }

  @action lockGame() {
    this.isLoading = true
    return fetch({
      method: 'POST',
      url: `/api/games/${this.store.gamePk}/lock`,
    })
      .then(action((isGameLocked) => (this.isGameLocked = isGameLocked)))
      .catch((err) => {
        console.error(err)
      })
      .finally(
        action(() => {
          this.isLoading = false
        })
      )
  }

  @action unlockGame() {
    this.isLoading = true
    return fetch({
      method: 'POST',
      url: `/api/games/${this.store.gamePk}/unlock`,
    })
      .then(action((isGameLocked) => (this.isGameLocked = isGameLocked)))
      .catch((err) => {
        console.error(err)
      })
      .finally(
        action(() => {
          this.isLoading = false
        })
      )
  }

  @action postApprovalStatus() {
    let officialScorer = ''
    for (let official of this.boxscore.officials) {
      if (official.officialType == 'Official Scorer') {
        officialScorer = official?.official?.fullName || ''
        break
      }
    }
    let confirmation = false
    if (officialScorer != '') {
      confirmation = window.confirm(
        `Official Scorer (${officialScorer}) confirms all information and data in this box score has been approved. Click 'OK' for final authorization before notifying the stringer to finalize their game file.`
      )
    } else {
      confirmation = window.confirm(
        `Official Scorer confirms all information and data in this box score has been approved. Click 'OK' for final authorization before notifying the stringer to finalize their game file.`
      )
    }
    if (confirmation) {
      this.isLoading = true
      return fetch({
        method: 'POST',
        data: {
          gamePk: this.store.gamePk,
          approvedStatus: true,
          approvedBy: officialScorer,
        },
        url: `/api/games/scoresheetApproval/new`,
      })
        .then(
          action(() => {
            this.alreadyApproved = true
          })
        )
        .catch((err) => {
          console.error(err)
        })
        .finally(
          action(() => {
            this.isLoading = false
          })
        )
    }
  }

  @observable isLoading = false

  @action load() {
    if (this.isLoading) {
      return
    }
    if (this.shouldFetch) {
      this.isLoading = true
      this.setInt()
      this.update()
        .then((done) => {
          this.isLoading = false
          this.fetchReplays()
        })
        .catch((error) => {
          console.error(error)
          this.isLoading = false
        })
    }
  }

  _updating = false

  update(forceUpdate) {
    if (!this.shouldFetch) {
      this.clearInt()
      return Promise.resolve()
    }

    if (this._updating) {
      console.info('Slow network detected. Skipping polling cycle.')
      return Promise.resolve()
    }

    if (!forceUpdate && this.isFinal && !this.isLocked) {
      this.exitLiveMode()
      return Promise.resolve()
    }

    this._updating = true
    return this.fetch()
      .then((data) => {
        this.merge(data)
        this._updating = false
      })
      .catch((error) => {
        this._updating = false
        //throw error
      })
  }

  @observable replays = {}
  @computed get replayParams() {
    return {
      gamePk: this.store.gamePk,
    }
  }

  fetchReplays() {
    const { homeTeam, awayTeam } = this.replayParams

    if (!this.schedule.gameDate || homeTeam === 'Home' || awayTeam === 'Away') {
      return
    }
    if (this.page === 'scoresheet') {
      return fetch({
        url: `/api/games/replays`,
        params: this.replayParams,
      })
        .then(
          action((data) => {
            this.replays = data
          })
        )
        .catch((err) => {
          console.error(err)
        })
    } else {
      this.replays = []
    }
  }

  @action resetStringerData() {
    this.stringerData = toJS(this._stringerData)
  }

  @action merge(data) {
    let initial = false

    if (this.playIdx == 0 && this.atBatIdx == 0 && this.atBats.length == 0) {
      initial = true
    }

    let {
      isGameLocked,
      stringerData,
      resolvedPlayMap,
      guids,
      schedule,
      codePageUrl,
      backlog,
      hawkeyeErrors,
      boxscore,
      automaticRunners,
      blockedVideos,
      approvalData,
      trackingErrorsCount,
      trackingErrorsLastUpdatedTs,
      rosters,
    } = data
    this.setApprovalData()
    this.isGameLocked = isGameLocked

    this.blockedVideos = blockedVideos
    if (stringerData) {
      try {
        this.store.dware.initialize(stringerData, rosters)
      } catch (e) {
        console.error(e)
      }

      this.stringerData = stringerData
      this._stringerData = stringerData
      this.resetNonEventUpdates()
      if (this.stringerData.isFinal) {
        try {
          //reset lineups can fail when all lineup data is not present
          this.resetLineups()
        } catch (e) {
          console.error(e)
        }
      }
    }

    this.trackingErrorsCount = trackingErrorsCount
    this.trackingErrorsLastUpdatedTs = trackingErrorsLastUpdatedTs

    if (schedule) {
      this.sportId = schedule.teams.home.team.sport.id
    }

    if (resolvedPlayMap) {
      this.resolvedPlayMap = resolvedPlayMap
    }

    if (backlog) {
      this.backlog = backlog
    }

    if (hawkeyeErrors) {
      this.hawkeyeErrors = hawkeyeErrors
    }

    if (guids) {
      this.guids = guids
    }

    if (schedule) {
      this.schedule = schedule
    }

    if (codePageUrl) {
      this.codePageUrl = codePageUrl
    }

    if (automaticRunners) {
      this.automaticRunners = automaticRunners
    }

    if (approvalData) {
      this.approvalData = approvalData
    }

    if (initial) {
      this.setLastPlay()
    }

    if (boxscore) {
      let { teams = {} } = boxscore

      let { home = {}, away = {} } = teams

      let awayPlayers = get(away, 'players', {})
      let homePlayers = get(home, 'players', {})

      Object.values(awayPlayers).forEach((player) => {
        let playerPosition = get(player, 'position', '')
        if (playerPosition.includes('-')) {
          let onClick = () => {
            this.togglePlayerPositionalData(player.person.id, false)
          }
          player.clickable = true
          player.onClick = onClick
        }
      })

      Object.values(homePlayers).forEach((player) => {
        let playerPosition = get(player, 'position', '')
        if (playerPosition.includes('-')) {
          let onClick = () => {
            this.togglePlayerPositionalData(player.person.id, true)
          }
          player.clickable = true
          player.onClick = onClick
        }
      })
      this.boxscore = boxscore
    }
    if (rosters) {
      this.rosters = rosters
    }
  }

  @observable int = null

  setInt() {
    clearInterval(this.int)
    this.int = null
    if (this.shouldFetch && !this.store.isNonGameEventPage) {
      this.int = setInterval(() => {
        this.update()
      }, this.POLLING_INTERVAL)
    }
  }

  @observable stringerData = {}
  @observable approvalData = {}
  @observable blockedVideos = []
  @observable automaticRunners = []
  @observable nonEventUpdates = {}
  @observable guids = []
  @observable schedule = {}
  @observable resolvedPlayMap = {}
  @observable backlog = []
  @observable hawkeyeErrors = []
  @observable status = {}
  @observable codePageUrl = ''
  @observable lastPitch = {}
  @observable sportId = {}
  @observable trackingErrorsCount = 0
  @observable trackingErrorsLastUpdatedTs
  @observable rosters = {}

  @action resetNonEventUpdates() {
    this.nonEventUpdates = {
      awayStarters: {},
      homeStarters: {},
      umpires: {},
      scoreKeepers: {
        primaryStringer: {},
        secondaryStringer: {},
        officialScorer: {},
      },
      weather: {
        windSpeed: '',
        windDirection: '',
        temperature: '',
        sky: '',
        hasChange: false,
      },
      postGameNotes: {
        gameDelayMins: '',
        gameElapsedMins: '',
        attendance: '',
        startTime: '',
      },
      decisions: {
        win: '',
        loss: '',
        save: '',
        awayHolds: [],
        homeHolds: [],
        awayBlownSaves: [],
        homeBlownSaves: [],
      },
    }
  }

  @action reset(skipSchedule) {
    this.store.hash.merge({
      atBatIdx: null,
      playIdx: null,
    })
    this._isLocked = false
    this.stringerData = {}
    this.approvalData = {}
    this.resetApprovedData()
    this.automaticRunners = []
    this.guids = []
    if (!skipSchedule) {
      this.schedule = {}
    }
    this.resolvedPlayMap = {}
    this.status = {}
    this.codePageUrl = ''
    this.lastPitch = {}
    this.resetNonEventUpdates()
    this.eventFilter = ''
  }

  @computed get gameInfo() {
    let { gameInfo = {} } = this.stringerData

    return gameInfo
  }

  @computed get venue() {
    let { gameInfo = {} } = this

    let { venueId: id, venueName: name, state, city, country } = gameInfo

    return {
      id,
      name,
      city,
      state,
      country,
    }
  }

  @computed get venueId() {
    return this.venue.id || null
  }

  @computed get homeTeamSportId() {
    return this.gameInfo.homeSportId
  }

  @computed get awayTeamName() {
    let { awayFull = 'Away' } = this.gameInfo

    return awayFull
  }

  @computed get awayTeamShortName() {
    let { awayBrief, awayFull } = this.gameInfo

    return awayFull || awayBrief || 'Away'
  }

  @computed get awayTeamAbbrev() {
    let { awayAbbrev } = this.gameInfo

    return awayAbbrev || 'Away'
  }

  @computed get homeTeamName() {
    let { homeFull = 'Home' } = this.gameInfo

    return homeFull
  }

  @computed get homeTeamShortName() {
    let { homeBrief, homeFull } = this.gameInfo

    return homeFull || homeBrief || 'Home'
  }

  @computed get homeTeamAbbrev() {
    let { homeAbbrev } = this.gameInfo

    return homeAbbrev || 'Home'
  }

  @computed get gameDate() {
    let date = 'TBD'

    if (this.store.game.schedule && this.store.game.schedule.officialDate) {
      let _date = this.store.game.schedule.officialDate
      date = moment(_date).format('MMM Do, YYYY')
      if (this.store.game.schedule.gameNumber > 1) {
        var suffix = 'TBD'
        if (
          this.store.game.schedule.doubleHeader == 'Y' &&
          this.store.game.schedule.gameNumber > 1
        ) {
          suffix = 'Game ' + this.store.game.schedule.gameNumber
        }
        date = date + ', ' + suffix
      }
    }

    return date
  }

  @computed get theGamePk() {
    let { gamePk = '' } = this.stringerData

    return gamePk
  }

  @computed get isGameOver() {
    let { gameStateInfo = {} } = this.stringerData

    let { gameInfo = {} } = gameStateInfo

    let { gameStatusInd = '' } = gameInfo

    if (gameStatusInd[0] == 'O' || gameStatusInd[0] == 'F') {
      return true
    } else {
      return false
    }
  }

  @computed get canGameBeUnlocked() {
    return this.store.auth.hasGameLockPermission
  }

  @computed get gameStatusInd() {
    return get(this.stringerData, 'gameStateInfo.gameInfo.gameStatusInd', '')
  }

  @computed get gameStatusText() {
    const { gameStateInfo } = this.stringerData

    if (!gameStateInfo) {
      return this.scheduleStatusText
    }

    const { gameInfo = {} } = gameStateInfo
    const { gameStatusInd = '', status = '', reason = '' } = gameInfo
    return gameStatusInd + ' - ' + status + (reason ? ' (' + reason + ')' : '')
  }

  @computed get scheduleStatusText() {
    const codedGameState = get(this.schedule, 'status.codedGameState')
    const detailedState = get(this.schedule, 'status.detailedState')
    if (!codedGameState) {
      return ''
    }
    return `${codedGameState} - ${detailedState}`
  }

  @computed get currentPlayText() {
    const separator = ' - '
    const abstractGameCode = get(this.schedule, 'status.abstractGameCode')
    const officialDate = get(this.schedule, 'officialDate')
    const dateFormat = 'YYYY-MM-DD'
    const dateText = officialDate ? moment(officialDate).format(dateFormat) : ''

    const awayName = get(this.schedule, 'teams.away.team.abbreviation', '')
    const homeName = get(this.schedule, 'teams.home.team.abbreviation', '')
    const awayScore = get(this.schedule, 'linescore.teams.away.runs', 0)
    const homeScore = get(this.schedule, 'linescore.teams.home.runs', 0)

    const teamText =
      awayName && homeName
        ? `${awayName} ${awayScore} @ ${homeName} ${homeScore}`
        : ''

    if (['P', 'F'].includes(abstractGameCode)) {
      return [this.gameStatusText, teamText, dateText]
        .filter(Boolean)
        .join(separator)
    }

    const batterName = get(
      this.schedule,
      'linescore.offense.batter.fullName',
      ''
    )
    const pitcherName = get(
      this.schedule,
      'linescore.defense.pitcher.fullName',
      ''
    )

    if (!batterName || !pitcherName) {
      return ''
    }

    const atBatNumber = this.atBatCount
    const currentInning = get(this.schedule, 'linescore.currentInning', '')
    const inningState = get(this.schedule, 'linescore.inningState', '')
    const balls = get(this.schedule, 'linescore.balls', 0)
    const strikes = get(this.schedule, 'linescore.strikes', 0)
    const outs = get(this.schedule, 'linescore.outs', 0)

    const inningText =
      currentInning && inningState ? `${inningState} ${currentInning}` : ''
    const atBatText = atBatNumber ? `AB ${atBatNumber}` : ''

    const matchupText =
      batterName && pitcherName
        ? `${pitcherName} pitching to ${batterName}`
        : ''
    const countText = [balls, strikes, outs].every((i) => i !== undefined)
      ? `${balls}-${strikes}, ${outs} Out${outs === 1 ? '' : 's'}`
      : ''

    return [
      this.gameStatusText,
      teamText,
      inningText,
      atBatText,
      matchupText,
      countText,
      dateText,
    ]
      .filter(Boolean)
      .join(separator)
  }

  @computed get currentMatchup() {
    var batter = {}
    var pitcher = {}
    if (this._atBats.length > 0) {
      var currentAtBat = this._atBats[this._atBats.length - 1]
      if (currentAtBat.plays.length > 0) {
        var currentPlay = currentAtBat.plays[currentAtBat.plays.length - 1]
        if (currentPlay.gameState) {
          batter.id = currentPlay.gameState.batter
          batter.fullName = this.store.dware.getPlayerNameShortNoNumber(
            batter.id
          )
          var currentFielders = []
          if (currentPlay.gameState.team == 0) {
            currentFielders = currentPlay.gameState.homeLineup
          } else {
            currentFielders = currentPlay.gameState.awayLineup
          }
          for (var i = 0; i < currentFielders.length; i++) {
            var fielder = currentFielders[i]
            if (fielder.position == 1) {
              pitcher.id = fielder.player
              pitcher.fullName = this.store.dware.getPlayerNameShortNoNumber(
                pitcher.id
              )
              break
            }
          }
        }
      }
    }

    return {
      batter,
      pitcher,
    }
  }

  // @computed get currentBatter(){
  //     let {
  //         playStrings = [],
  //         awayRoster = [],
  //         homeRoster = [],
  //     } = this.stringerData
  //
  //     let batters = playStrings.filter(string => string.playStringFull.includes('batter,'))
  //
  //     if (!batters.length){
  //         return 'Batter'
  //     }
  //
  //     let string = batters[batters.length - 1] || {}
  //
  //     let {
  //         playStringFull = ''
  //     } = string
  //
  //     let id = playStringFull.replace('batter,', '').replace(/\{\d+\}/, '').trim()
  //     let batter = awayRoster.concat(homeRoster).find(p => p.id == id) || {}
  //
  //     return Object.assign({}, batter, {
  //         fullName: batter.name || 'Batter'
  //     })
  // }

  @computed get currentBatter() {
    return this.currentMatchup.batter
  }

  @computed get currentPitcher() {
    return this.currentMatchup.pitcher
  }

  @computed get pitches() {
    let pitches = []
    for (var i = 0; i < this.guids.length; i++) {
      let guid = this.guids[i]
      let pitch = {
        playId: guid.guid,
        isSelected: false,
        isAssigned: false,
      }

      for (var key in guid) {
        if (guid.hasOwnProperty(key)) {
          pitch[key] = guid[key]
        }
      }

      if (this.store.audit.assignedPitchMap[pitch.playId]) {
        pitch.isAssigned = true
      }

      // if (this.store.audit.selectedPitchMap[pitch.playId]){
      //     pitch.isSelected = true
      // }

      var timeZoneId =
        this.schedule && this.schedule.venue && this.schedule.venue.timeZone
          ? this.schedule.venue.timeZone.id
          : 'America/New_York'

      var pitchDateVenue = DateTime.fromISO(pitch.time, {
        zone: timeZoneId,
      }).toFormat('yyyy-LL-dd')

      var timeFormat = 'h:mm:ss a'
      if (pitchDateVenue != this.schedule.officialDate) {
        timeFormat = 'h:mm:ss a (LL/dd)'
      }

      pitch.timeFormatted = DateTime.fromISO(pitch.time, {
        zone: timeZoneId,
      }).toFormat(timeFormat)

      if (pitch.isPitch) {
        pitch.auditType = 'Pitch'
      } else if (pitch.isPickoff) {
        pitch.auditType = 'Pickoff'
      } else {
        pitch.auditType = 'No Pitch'
      }

      if (pitch.isHit) {
        pitch.auditType += '+Hit'
      }

      if (pitch.isManual) {
        pitch.auditType = '*' + pitch.auditType
      }

      pitches.push(pitch)
    }

    return pitches
  }

  @computed get untaggedPitches() {
    return this.pitches.filter((pitch) => {
      return !pitch.isAssigned
    })
  }

  @computed get scrubbedPitches() {
    return this.pitches.filter((pitch) => {
      return pitch.isScrubbed
    })
  }

  @computed get currentHEErrorState() {
    let currentState = 'OK' //no errors

    if (!this.hawkeyeErrors.length) {
      return currentState
    }
    const baseTime = moment.utc(this.hawkeyeErrors[0].timestamp)

    for (const err of this.hawkeyeErrors) {
      const { errorMessage = '', timestamp = '' } = err
      const errTime = moment.utc(timestamp)

      if (baseTime.diff(errTime, 'minutes') > 10) {
        break
      }

      if (errorMessage.toLowerCase().includes('critical')) {
        currentState = 'C'
        break
      } else if (errorMessage.toLowerCase().includes('error')) {
        currentState = 'E'
      } else if (
        errorMessage.toLowerCase().includes('warning') &&
        currentState !== 'E'
      ) {
        currentState = 'W'
      }
    }

    return currentState
  }

  @computed get eventPlayIdxMap() {
    return this.events.reduce((map, event) => {
      let key = `${event.atBatIdx}-${event.playIdx}`
      map[key] = event

      return map
    }, {})
  }

  @computed get eventPlayIdMap() {
    return this.events.reduce((map, event) => {
      if (event.playId) {
        map[event.playId] = event
      }

      return map
    }, {})
  }

  @computed get pitchMap() {
    return this.pitches.reduce((map, pitch) => {
      if (pitch.playId) {
        map[pitch.playId] = pitch
      }

      return map
    }, {})
  }

  @computed get _atBats() {
    return this.stringerData ? this.stringerData.atBats || [] : []
  }

  @computed get allPlays() {
    return this.atBats.reduce((array, atBat) => {
      let plays = atBat.plays || []

      return array.concat(plays)
    }, [])
  }

  @computed get atBats() {
    let atBats = []

    let playObj = {}

    let inningStatMap = {}

    let awayScore = 0
    let homeScore = 0

    var eventFilters = null
    if (this.eventFilter) {
      eventFilters = {}
      var filterArray = this.eventFilter.split(',')
      for (var i = 0; i < filterArray.length; i++) {
        eventFilters[filterArray[i]] = true
      }
    }

    for (let i = 0; i < this._atBats.length; i++) {
      let _atBat = this._atBats[i]

      /**
       * _atBat
       * @type {Object} _atBat - at_bat object from stringer
       * @property {Number} atBatIdx
       * @property {Number} inning
       * @property {Array} plays
       * @property {String} topInningSw
       */

      let inningKey = _atBat.inning + '_' + _atBat.topInningSw
      if (!inningStatMap[inningKey]) {
        inningStatMap[inningKey] = {
          runs: 0,
          hits: 0,
          outs: 0,
          errors: 0,
          lob: 0,
          awayScore: awayScore,
          homeScore: homeScore,
        }
      }

      let inningKeyValue = inningStatMap[inningKey]

      let atBat = {
        atBatIdx: _atBat.atBatIdx,
        atBatNumber: _atBat.atBatIdx + 1,
        inning: _atBat.inning,
        topInningSw: _atBat.topInningSw,
        frame: `${_atBat.topInningSw == 'Y' ? 'Top' : 'Bottom'} ${
          _atBat.inning
        }`,
        frameRHE: inningKeyValue,
        plays: [],
        playByPlay: 'In Progress',
        type: 'result',

        // Editing
        eventCode: '',
        pitchSequence: '',
        pitchClassfication: '',
        gameState: {},
        playEvent: {},

        // Metric Errors
        numberOfMetricErrors: 0,
        numberOfPlaysWithMetricErrors: 0,
        numberOfResolvedPlays: 0,
        numberOfPlaysWithoutMetrics: 0,

        // Pitch Tagging/Audit
        numberOfPitches: 0,
        numberOfUntaggedPitches: 0,
        numberOfPickoffs: 0,
        numberOfUntaggedPickoffs: 0,
        numberOfResends: 0,
        numberOfScrubs: 0,
        numberOfFiltered: 0,
      }

      if (this.currentPitcher.fullName && this.currentBatter.fullName) {
        atBat.playByPlay += ` (${this.currentPitcher.fullName} to ${this.currentBatter.fullName})`
      }

      let _plays = _atBat.plays || []
      if (_plays.length > 0) {
        atBat.gameState = _plays[_plays.length - 1].gameState
      }
      for (let j = 0; j < _plays.length; j++) {
        let _play = _plays[j]

        /**
         * _play
         * @type {Object} _atBat - play object from stringer

         * @property {Number} pitchNum - pitch number
         * @property {Array} reviews - array of reviews
         * @property {Array} violations - array of violations
         * @property {String} playString
         * @property {String} type - required
         * @property {Number} playIdx - required
         * @property {String} pitchCode - pitch type
         * @property {Boolean} runnersGoing - pitch, if runners going
         * @property {String} guid - playId if tracked pitch is tagged
         * @property {String} playByPlay - play/result, play by play
         * @property {String} eventCode - play/result, playString
         * @property {HashMap} gameState - hash map of play info (runners, hit trajectory, fielders, etc....)
         * @property {HashMap} playEvent - hash map of play info (runners, hit trajectory, fielders, etc....)
         * @property {Number} batPos - sub, 1-9 batting position
         * @property {String} subPlayer - sub, sub player id
         * @property {String} defPos - sub, 1-11 defensive position
         * @property {Number} subTeam - sub, 0-1, 0 == away, 1 == home
         */

        let play = {
          atBatIdx: _atBat.atBatIdx,
          atBatNumber: _atBat.atBatIdx + 1,
          inning: _atBat.inning,
          topInningSw: _atBat.topInningSw,
          pitchNum: _play.pitchNum,
          reviews: [],
          violations: [],
          playString: _play.playString,
          type: _play.type,
          playIdx: _play.playIdx,
          _playIdx: _play._playIdx >= 0 ? _play._playIdx : _play.playIdx,
          pitchCode: _play.pitchCode,
          runnersGoing: _play.runnersGoing,
          guid: _play.guid,
          dwareCode: _play.dwareCode,
          playByPlay: _play.playByPlay
            ? _play.playByPlay.replace(/;$/, '')
            : null,
          eventCode: _play.eventCode,
          timecode: _play.timecode,
          gameState: _play.gameState,
          playEvent: _play.playEvent,
          batPos: _play.batPos,
          subPlayer: _play.subPlayer,
          defPos: _play.defPos,
          subTeam: _play.subTeam,
          playId: _play.guid,
          frame: atBat.frame,
          frameRHE: atBat.frameRHE,
          pitchSequence: atBat.pitchSequence,
          pitchClassfication: _play.pitchClassfication,
          isAssigned: false,
          isDiscrepant: false,
          isTagged: false,
          isScrubbed: false,
          isResolved: false,
          numberOfMetricErrors: 0,
          isFiltered: false,
          isMissingMetrics: false,
        }

        let _reviews = _play.reviews || []
        for (let k = 0; k < _reviews.length; k++) {
          let _review = _reviews[k]

          /**
           * _review
           * @type {Object} - play review object from stringer
           * @property {String} code
           * @property {Number} idx
           * @property {String} overturnedCode
           * @property {String} playString
           * @property {String} reviewStatus
           * @property {String} reviewedBy
           * @property {Number} playerId
           */

          let review = {
            code: _review.code,
            idx: _review.idx,
            overturnedCode: _review.overturnedCode,
            overturned: _review.overturned,
            inProgress: _review.inProgress,
            playString: _review.playString,
            reviewStatus: _review.reviewStatus,
            reviewedBy: _review.reviewedBy,
            playerId: _review.playerId,
          }

          play.reviews.push(review)
          // end reviews loop
        }

        let _violations = _play.violations || []
        for (let l = 0; l < _violations.length; l++) {
          let _violation = _violations[l]

          /**
           * _violation
           * @type {Object} - play violation object
           * @property {Number} idx
           * @property {Number} playerId
           * @property {Number} teamId
           * @property {number} violationTypeId
           * @property {String} violationType
           */

          let violation = {
            idx: _violation.idx,
            playerId: _violation.playerId,
            teamId: _violation.teamId,
            violationTypeId: _violation.violationTypeId,
            violationType: _violation.violationType,
          }

          play.violations.push(violation)
          // end violations loop
        }

        let _pitch = this.pitchMap[_play.guid]

        /**
         * _pitch
         * @type  {Object} _pitch tracked pitch event
         * @property {String} playId
         * @property {String} gamePk
         * @property {String} gameDate
         * @property {String} timeCode
         * @property {String} guid
         * @property {String} atBatNumber
         * @property {String} pitchNumber
         * @property {String} pickoffNumber
         * @property {String} gameMode
         * @property {String} inning
         * @property {String} isTopInning
         * @property {String} isPitch
         * @property {String} isPickoff
         * @property {String} isHit
         * @property {String} isManual
         * @property {String} rawFile
         * @property {String} parsedFile
         * @property {String} time
         * @property {String} startTime
         * @property {String} endTime
         * @property {String} pitchTime
         * @property {String} createdAt
         * @property {String} updatedAt
         * @property {String} hasUpdates
         * @property {String} numberOfMetricErrors
         * @property {String} timeCodeOffset
         * @property {String} isScrubbed
         */

        if (
          this.store.audit.selectedPlayMap[
            `${_atBat.atBatIdx}-${_play.playIdx}`
          ]
        ) {
          play.isSelected = true
        }

        if (_play.playByPlay == 'Non Pitch' && _plays[[j + 1]]) {
          play.playByPlay = `${_play.playByPlay} (${_plays[j + 1].playByPlay})`
        }

        if (_play.type == 'badj') {
          play.playByPlay =
            'Bat Side Adjust: Now Batting ' +
            (_play.batSide == 'L'
              ? 'Left Handed'
              : _play.batSide == 'R'
              ? 'Right Handed'
              : 'Unknown')
        } else if (_play.type == 'padj') {
          play.playByPlay =
            'Pitch Hand Adjust: Now Pitching ' +
            (_play.pitchHand == 'L'
              ? 'Left Handed'
              : _play.pitchHand == 'R'
              ? 'Right Handed'
              : 'Unknown')
        } else if (
          _play.type == 'pitch' &&
          !_play.playByPlay &&
          _play.pitchCode &&
          this.stringerData &&
          this.stringerData.pitchCodes
        ) {
          for (var k = 0; k < this.stringerData.pitchCodes.length; k++) {
            var pitchCodeObj = this.stringerData.pitchCodes[k]
            if (pitchCodeObj.value == _play.pitchCode) {
              play.playByPlay = pitchCodeObj.label
              break
            }
          }
        }

        const backlog = (play.backlog = this.backlog.filter(
          (item) => item.playId == _play.guid
        ))

        if (_pitch) {
          play.isScrubbed = _pitch.isScrubbed
          play.numberOfMetricErrors = _pitch.metricErrors?.length

          if (!atBat.plays.find((play) => play.guid === _pitch.guid)) {
            atBat.numberOfMetricErrors += _pitch.metricErrors?.length
          }

          play.time = _pitch.time

          if (play.numberOfMetricErrors) {
            atBat.numberOfPlaysWithMetricErrors += 1
          }

          if (this.resolvedPlayMap[_play.guid]) {
            play.isResolved = true
            atBat.numberOfResolvedPlays += 1
          }
        }

        if (_play.guid && !_pitch) {
          if (i != this._atBats.length - 1 || j != _plays.length - 1) {
            play.isResend = true
            atBat.numberOfResends += 1
          }
        }

        var playHasRun = false
        var playHasUnearnedRun = false
        var playHasNoRbi = false
        var playHasError = false
        var movementReasonsOnPlay = {}
        var fieldersSeen = {}

        if (_play.type == 'play' || _play.type == 'result') {
          var playEvent = _play.playEvent
          if (playEvent && playEvent.playType) {
            if (
              playEvent.playType == PlayTypes.SINGLE ||
              playEvent.playType == PlayTypes.DOUBLE ||
              playEvent.playType == PlayTypes.TRIPLE ||
              playEvent.playType == PlayTypes.HOME_RUN
            ) {
              inningKeyValue.hits++
            } else if (playEvent.playType == PlayTypes.CATCHERS_INTERFERENCE) {
              inningKeyValue.errors++
              playHasError = true
            }
          }
          if (playEvent && playEvent.primaryFielderCredits) {
            for (var k = 0; k < playEvent.primaryFielderCredits.length; k++) {
              var fielder = playEvent.primaryFielderCredits[k]
              if (
                fielder.credit == 'error' ||
                fielder.credit == 'throwing_error' ||
                fielder.credit == 'dropped_throw' ||
                fielder.credit == 'interference'
              ) {
                inningKeyValue.errors++
                playHasError = true
              }
              if (
                _play.gameState &&
                _play.gameState.fielders &&
                _play.gameState.fielders[parseInt(fielder.position) - 1]
              ) {
                fieldersSeen[
                  parseInt(
                    _play.gameState.fielders[parseInt(fielder.position) - 1]
                  )
                ] = true
              }
            }
          }
          if (playEvent && playEvent.runnerMovements) {
            var menOnBase = 0
            for (var runnerBase in playEvent.runnerMovements) {
              var runner = playEvent.runnerMovements[runnerBase]
              menOnBase++
              if (runner.movements) {
                for (var k = 0; k < runner.movements.length; k++) {
                  var movement = runner.movements[k]
                  if (movement.reason) {
                    movementReasonsOnPlay['reason_' + movement.reason] = true
                  }
                  if (movement.out) {
                    inningKeyValue.outs++
                    menOnBase--
                  } else if (movement.scored) {
                    playHasRun = true
                    inningKeyValue.runs++
                    menOnBase--
                    if (_atBat.topInningSw == 'Y') {
                      awayScore++
                      inningKeyValue.awayScore++
                    } else {
                      homeScore++
                      inningKeyValue.homeScore++
                    }

                    if (movement.runRulings) {
                      for (var l = 0; l < movement.runRulings.length; l++) {
                        if (movement.runRulings[l] == 'UR') {
                          playHasUnearnedRun = true
                        }
                        if (movement.runRulings[l] == 'NR') {
                          playHasNoRbi = true
                        }
                      }
                    }
                  }

                  if (movement.fielderCredits) {
                    for (var l = 0; l < movement.fielderCredits.length; l++) {
                      var movementFielder = movement.fielderCredits[l]
                      if (
                        _play.gameState &&
                        _play.gameState.fielders &&
                        _play.gameState.fielders[
                          parseInt(movementFielder.position) - 1
                        ]
                      ) {
                        fieldersSeen[
                          parseInt(
                            _play.gameState.fielders[
                              parseInt(movementFielder.position) - 1
                            ]
                          )
                        ] = true
                      }
                      if (
                        movementFielder.credit == 'error' ||
                        movementFielder.credit == 'throwing_error' ||
                        movementFielder.credit == 'dropped_throw' ||
                        movementFielder.credit == 'interference'
                      ) {
                        inningKeyValue.errors++
                        playHasError = true
                      }
                    }
                  }
                }
              }
            }
            if (inningKeyValue.outs == 3) {
              inningKeyValue.lob = menOnBase
            }
          }
        }

        if (eventFilters) {
          if (_play.type == 'play' || _play.type == 'result') {
            if (!_play.playEvent || !_play.gameState) {
              play.isFiltered = true
            }
            if (
              !eventFilters[_play.gameState.batter] &&
              !eventFilters[_play.playEvent.playType]
            ) {
              play.isFiltered = true
            }
          } else {
            play.isFiltered = true
          }

          if (play.playEvent && play.playEvent.flags) {
            for (var k = 0; k < play.playEvent.flags.length; k++) {
              if (eventFilters['flag_' + play.playEvent.flags[k]]) {
                play.isFiltered = false
                break
              }
            }
          }

          for (var fielderIdSeen in fieldersSeen) {
            if (eventFilters[fielderIdSeen]) {
              play.isFiltered = false
              break
            }
          }

          if (eventFilters['top_inning'] && _atBat.topInningSw == 'Y') {
            play.isFiltered = false
          } else if (
            eventFilters['bottom_inning'] &&
            _atBat.topInningSw != 'Y'
          ) {
            play.isFiltered = false
          }

          for (var movementReasonSeen in movementReasonsOnPlay) {
            if (eventFilters[movementReasonSeen]) {
              play.isFiltered = false
            }
          }

          if (eventFilters['run_scoring_play'] && playHasRun) {
            play.isFiltered = false
          } else if (eventFilters['unearned_run'] && playHasUnearnedRun) {
            play.isFiltered = false
          } else if (eventFilters['no_rbi'] && playHasNoRbi) {
            play.isFiltered = false
          } else if (
            (eventFilters[PlayTypes.ERROR] ||
              eventFilters[PlayTypes.ERROR_BASERUNNING]) &&
            playHasError
          ) {
            play.isFiltered = false
          }

          if (
            eventFilters['pitching_change'] &&
            _play.type == 'sub' &&
            play.defPos == 1
          ) {
            play.isFiltered = false
          } else if (
            eventFilters['pinch_hitter'] &&
            _play.type == 'sub' &&
            play.defPos == 11
          ) {
            play.isFiltered = false
          } else if (
            eventFilters['pinch_runner'] &&
            _play.type == 'sub' &&
            play.defPos == 12
          ) {
            play.isFiltered = false
          } else if (
            eventFilters['defensive_sub'] &&
            _play.type == 'sub' &&
            parseInt(play.defPos) >= 1 &&
            parseInt(play.defPos) <= 9
          ) {
            play.isFiltered = false
          }

          if (eventFilters['ejection'] && _play.type == 'eject') {
            play.isFiltered = false
          }

          if (eventFilters['at_bat_' + play.atBatNumber]) {
            play.isFiltered = false
          }

          if (eventFilters['inning_' + inningKey]) {
            play.isFiltered = false
          }

          if (
            eventFilters['pitcher_pitch_timer_violation'] &&
            _play.type === 'pitch' &&
            _play.pitchCode === 'VP'
          ) {
            play.isFiltered = false
          }

          if (
            eventFilters['catcher_pitch_timer_violation'] &&
            _play.type === 'pitch' &&
            _play.pitchCode === 'VC'
          ) {
            play.isFiltered = false
          }

          if (
            eventFilters['defensive_shift_violation'] &&
            _play.type === 'pitch' &&
            _play.pitchCode === 'VS'
          ) {
            play.isFiltered = false
          }

          if (
            eventFilters['batter_pitch_timer_violation'] &&
            _play.type === 'pitch' &&
            _play.pitchCode === 'AC'
          ) {
            play.isFiltered = false
          }

          if (
            eventFilters['batter_timeout_violation'] &&
            _play.type === 'pitch' &&
            _play.pitchCode === 'AB'
          ) {
            play.isFiltered = false
          }

          if (eventFilters['mound_visit'] && _play.type === 'CV') {
            play.isFiltered = false
          }

          if (eventFilters['batter_timeout'] && _play.type === 'BT') {
            play.isFiltered = false
          }

          if (play.isFiltered) {
            atBat.numberOfFiltered++
          }
        }

        if (_play.type == 'pitch') {
          play.coordX = _play.coordX
          play.coordY = _play.coordY

          if (!['.', '+1', '+2', '+3'].includes(_play.pitchCode)) {
            play.isExpandable = true
          }

          if (_play.runnersGoing) {
            atBat.pitchSequence += '>'
          }

          if (_play.reviews && _play.reviews.length > 0) {
            atBat.pitchSequence += '^'
          }

          if (!['.'].includes(_play.pitchCode)) {
            if (
              _play.pitchCode.length > 1 &&
              /^[a-zA-Z]+$/.test(_play.pitchCode)
            ) {
              atBat.pitchSequence += `[${_play.pitchCode}]`
            } else {
              atBat.pitchSequence += _play.pitchCode
            }
            play.pitchSequence = atBat.pitchSequence // set the sequence at this time
          }

          if (!['V'].includes(_play.pitchCode)) {
          }

          let automaticPitchCodes = [
            'V',
            'A',
            'VB',
            'VC',
            'VP',
            'VS',
            'AB',
            'AC',
          ]
          let notRealPitchCodes = [
            '1',
            '2',
            '3',
            '+1',
            '+2',
            '+3',
            '.',
            'N',
            'PSO',
          ].concat(automaticPitchCodes)

          if (
            // if pitch code is not pickoff/stepoff - automatic ball/strike, or another type of no pitch event
            // then it's a real pitch
            !notRealPitchCodes.includes(_play.pitchCode)
          ) {
            play.isAuditable = true
            atBat.numberOfPitches += 1
            play.realPitchNum = atBat.numberOfPitches
            play.pickoffNumber = atBat.numberOfPickoffs
            if (_play.guid) {
              play.isAssigned = true
            } else {
              atBat.numberOfUntaggedPitches += 1
            }

            if (_pitch) {
              if (play.atBatNumber != _pitch.atBatNumber) {
                atBat.hasDiscrepancies = true
                play.isDiscrepant = true
                play.discrepancy = `Stringer AB ${play.atBatNumber}, BOSS AB ${_pitch.atBatNumber}`

                if (play.realPitchNum != _pitch.pitchNumber) {
                  play.discrepancy = `Stringer AB ${play.atBatNumber}, BOSS AB ${_pitch.atBatNumber}, Stringer Pitch ${play.realPitchNum}, BOSS Pitch ${_pitch.pitchNumber}`
                }
              } else if (play.realPitchNum != _pitch.pitchNumber) {
                atBat.hasDiscrepancies = true
                play.isDiscrepant = true

                play.discrepancy = `Stringer Pitch ${play.realPitchNum}, BOSS Pitch ${_pitch.pitchNumber}`
              }
            }
          } else if (['1', '2', '3', 'PSO'].includes(_play.pitchCode)) {
            play.isAuditable = true

            atBat.numberOfPickoffs += 1
            play.pickoffNumber = atBat.numberOfPickoffs
            play.realPitchNum = atBat.numberOfPitches
            play.isPickoff = true

            if (_play.guid) {
              play.isAssigned = true
            } else {
              atBat.numberOfUntaggedPickoffs += 1
            }

            if (_pitch) {
              if (play.atBatNumber != _pitch.atBatNumber) {
                atBat.hasDiscrepancies = true
                play.isDiscrepant = true
                play.discrepancy = `Stringer AB ${play.atBatNumber}, BOSS AB ${_pitch.atBatNumber}`

                if (play.pickoffNumber != _pitch.pickoffNumber) {
                  play.discrepancy = `Stringer AB ${play.atBatNumber}, BOSS AB ${_pitch.atBatNumber}, Stringer Pickoff ${play.pickoffNumber}, BOSS Pickoff ${_pitch.pickoffNumber}`
                }
              } else if (play.pickoffNumber != _pitch.pickoffNumber) {
                atBat.hasDiscrepancies = true
                play.isDiscrepant = true

                play.discrepancy = `Stringer Pickoff ${play.pickoffNumber}, BOSS Pickoff ${_pitch.pickoffNumber}`
              }
            }
          } else if (
            ['N'].includes(_play.pitchCode) ||
            automaticPitchCodes.includes(_play.pitchCode)
          ) {
            play.isAuditable = true
            if (_play.guid) {
              play.isAssigned = true
            } else {
              atBat.numberOfUntaggedPickoffs += 1
            }
          }
        }

        // if the pitch is a non pitch event or automatic pitch, set the realPitchNum to the last pitch number
        if (
          ['N', '.', 'VC', 'VS', 'AC', 'AB', 'VB', 'VP', 'A', 'V'].includes(
            _play.pitchCode
          )
        ) {
          play.realPitchNum = atBat.numberOfPitches
        }

        if (_play.type == 'result') {
          Object.assign(atBat, play, {
            numberOfMetricErrors: atBat.numberOfMetricErrors,
          })
        }

        if (_play.updates) {
          if (_play.updates.edit && _play.updates.edit.action == 'update') {
            play.isEdited = true
          }

          if (_play.updates.edit && _play.updates.edit.action == 'delete') {
            play.isDeleted = true
          }

          if (_play.updates.edit && _play.updates.edit.action == 'insert') {
            play.isInserted = true
          }
        }

        if (
          ([
            'badj',
            'com',
            'CV',
            'BT',
            'eject',
            'injury',
            'padj',
            'play',
            'runner',
            'pitch',
            'sub',
            'ladj',
            'umpsub',
          ].includes(_play.type) ||
            _play.pitchCode === 'VP' ||
            _play.pitchCode === 'VC' ||
            _play.pitchCode === 'VS' ||
            _play.pitchCode === 'AC' ||
            _play.pitchCode === 'AB') &&
          _play.eventCode !== 'NP'
        ) {
          // atBats.push(play)
        }

        atBat.plays.push(play)
        // end plays loop
      }

      atBats.push(atBat)
      //end atBats loop
    }

    return atBats
  }

  @observable boxscore = {}

  @computed get linescore() {
    let seen = {}

    var awayTeamRuns = 0
    var homeTeamRuns = 0
    var awayTeamHits = 0
    var homeTeamHits = 0
    var awayTeamErrors = 0
    var homeTeamErrors = 0

    var innings = []

    var isGameOver = this.isGameOver

    for (var i = 0; i < this.atBats.length; i++) {
      let atBat = this.atBats[i]
      if (!seen[atBat.frame]) {
        var inning
        if (innings[parseInt(atBat.inning) - 1]) {
          inning = innings[parseInt(atBat.inning) - 1]
        } else {
          inning = {
            num: atBat.inning,
          }
          innings.push(inning)
        }
        atBat.isStartInning = true
        seen[atBat.frame] = true
        if (atBat.topInningSw == 'Y') {
          awayTeamRuns += atBat.frameRHE.runs
          awayTeamHits += atBat.frameRHE.hits
          homeTeamErrors += atBat.frameRHE.errors
          inning.away = {
            runs: atBat.frameRHE.runs || (atBat.frameRHE.outs == 3 ? 0 : ''),
          }
        } else {
          homeTeamRuns += atBat.frameRHE.runs
          homeTeamHits += atBat.frameRHE.hits
          awayTeamErrors += atBat.frameRHE.errors
          inning.home = {
            runs: atBat.frameRHE.runs || (atBat.frameRHE.outs == 3 ? 0 : ''),
          }
        }
      }
    }

    var gameInfo = this.gameInfo
    var scheduledInnings = 9
    if (gameInfo && gameInfo.scheduledInnings) {
      scheduledInnings = gameInfo.scheduledInnings
    }

    for (var i = innings.length; i < scheduledInnings; i++) {
      var newInningObject = {
        num: i + 1,
      }
      innings.push(newInningObject)
    }

    for (var i = 0; i < innings.length; i++) {
      var inningObject = innings[i]
      if (isGameOver) {
        if (!inningObject.away) {
          inningObject.away = {}
        }
        if (!inningObject.away.runs && inningObject.away.runs != 0) {
          inningObject.away.runs = 'x'
        }
        if (!inningObject.home) {
          inningObject.home = {}
        }
        if (!inningObject.home.runs && inningObject.home.runs != 0) {
          inningObject.home.runs = 'x'
        }
      }
    }

    return {
      innings: innings,
      away: {
        runs: awayTeamRuns,
        hits: awayTeamHits,
        errors: awayTeamErrors,
      },
      home: {
        runs: homeTeamRuns,
        hits: homeTeamHits,
        errors: homeTeamErrors,
      },
    }
  }

  @computed get frames() {
    let frames = []
    let seen = {}

    for (var i = 0; i < this.atBats.length; i++) {
      let atBat = this.atBats[i]
      if (!seen[atBat.frame]) {
        atBat.isStartInning = true
        seen[atBat.frame] = true
        var frameRHE = ''
        if (this.page == 'plays' && atBat.frameRHE) {
          var spacing = '&nbsp;&nbsp;&nbsp;'
          frameRHE =
            spacing +
            ' |' +
            spacing +
            ' R: ' +
            atBat.frameRHE.runs +
            ' / H: ' +
            atBat.frameRHE.hits +
            ' / E: ' +
            atBat.frameRHE.errors +
            ' / LOB: ' +
            atBat.frameRHE.lob +
            spacing +
            ' | ' +
            spacing +
            atBat.frameRHE.outs +
            ' Out ' +
            spacing +
            '| ' +
            spacing +
            this.awayTeamAbbrev +
            ': ' +
            atBat.frameRHE.awayScore +
            ' ' +
            this.homeTeamAbbrev +
            ': ' +
            atBat.frameRHE.homeScore
        }
        frames.push({
          frame: atBat.frame,
          RHE: frameRHE,
        })
      }
    }

    return frames
  }

  @computed get page() {
    let re = /\/games\/\d+\/([a-zA-Z]+)/
    let match = this.store.pathname.match(re)

    if (match && match.length > 1) {
      return match[1]
    }
  }

  clearInt() {
    clearInterval(this.int)
    this.int = null
  }

  @computed get lastAtBatIdx() {
    for (let i = this.atBats.length - 1; i >= 0; i--) {
      if (this.atBats[i]?.plays?.some((play) => play.type === 'pitch')) {
        return i
      }
    }

    return 0
  }

  @computed get atBatIdx() {
    return isNaN(Number(this.store.hash.atBatIdx))
      ? this.lastAtBatIdx
      : Number(this.store.hash.atBatIdx)
  }

  @computed get lastPlayIdx() {
    const plays = this.atBats[this.lastAtBatIdx]?.plays ?? []

    for (let i = plays.length - 1; i >= 0; i--) {
      if (plays[i].type === 'pitch') {
        return i
      }
    }

    return 0
  }

  @computed get playIdx() {
    return isNaN(Number(this.store.hash.playIdx))
      ? this.lastPlayIdx
      : Number(this.store.hash.playIdx)
  }

  @observable eventFilter = ''

  @action setEventFilter(value) {
    this.eventFilter = value
  }

  @action setAtBatIdx(atBatIdx) {
    this._setAtBatIdx(atBatIdx)
  }

  @action _setAtBatIdx(atBatIdx) {
    let atBat = this.atBats.find(
      (atBat) => atBat.atBatIdx == atBatIdx && atBat.plays
    )
    let plays = (atBat && atBat.plays) || []
    let playIdx = null

    if (atBat.plays && atBat.plays.length) {
      let plays = atBat.plays || []

      for (let i = 0; i < plays.length; i++) {
        let play = plays[i]

        if (play.type == 'pitch' && !['.'].includes(play.pitchCode)) {
          playIdx = play.playIdx
        }
      }
    }

    const hash = {
      atBatIdx,
    }

    if (playIdx >= 0) {
      hash.playIdx = playIdx
    }

    this.store.hash.merge(hash)
  }

  @action setPlayIdx(playIdx) {
    this.store.hash.set('playIdx', playIdx)
  }

  @action setLastPlay() {
    let atBats = this.atBats.filter((atBat) => atBat.type == 'result')

    if (!atBats.length) {
      return
    }

    let atBat = atBats[atBats.length - 1]
    let atBatIdx = atBat && atBat.atBatIdx

    this._setAtBatIdx(atBatIdx)
  }

  @computed get shouldFetch() {
    return (
      this.store.isAuthenticated &&
      (this.store.isGamePage || this.store.isNonGameEventPage) &&
      this.page !== 'uniforms' &&
      (this.store.gamePk || this.store.eventId)
    )
  }

  @computed get eventFilterOptions() {
    var atBats = this.stringerData.atBats

    var options = []

    if (!atBats) {
      return options
    }

    var playerIdsSeen = {}
    var playTypesSeen = {}
    var movementReasonsSeen = {}
    var flagsSeen = {}

    var hasScoringPlay = false
    var hasUnearnedRun = false
    var hasNoRbi = false
    var hasPitchingSub = false
    var hasPinchHitter = false
    var hasPinchRunner = false
    var hasDefensiveSub = false
    var hasEjection = false
    var hasPitcherPitchTimerViolation = false
    var hasCatcherPitchTimerViolation = false
    var hasDefensiveShiftViolation = false
    var hasBatterPitchTimerViolation = false
    var hasBatterTimeoutViolation = false
    var hasMoundVisit = false
    var hasBatterTimeout = false

    var inningMap = {}

    for (var i = 0; i < atBats.length; i++) {
      let atBat = atBats[i]
      var inningKey = atBat.inning + '_' + atBat.topInningSw
      if (!inningMap[inningKey]) {
        inningMap[inningKey] = true
        options.push({
          id: 'inning_' + inningKey,
          code: (atBat.topInningSw == 'Y' ? 'Top' : 'Bot') + ' ' + atBat.inning,
        })
      }
      let plays = atBat.plays ? atBat.plays : []
      for (var j = 0; j < plays.length; j++) {
        if (j == 0) {
          options.push({
            id: 'at_bat_' + (i + 1),
            code: 'At Bat ' + (i + 1),
          })
        }
        var play = plays[j]
        if (play.type == 'play' || play.type == 'result') {
          if (play.gameState && play.gameState.batter) {
            playerIdsSeen[play.gameState.batter] = true
          }
          if (play.playEvent) {
            playTypesSeen[play.playEvent.playType] = true
            if (play.playEvent.flags) {
              for (var k = 0; k < play.playEvent.flags.length; k++) {
                flagsSeen[play.playEvent.flags[k]] = true
              }
            }
            if (play.playEvent.primaryFielderCredits) {
              for (
                var k = 0;
                k < play.playEvent.primaryFielderCredits.length;
                k++
              ) {
                var fielder = play.playEvent.primaryFielderCredits[k]
                if (
                  play.playEvent.gameState &&
                  play.playEvent.gameState.fielders &&
                  play.playEvent.gameState.fielders[
                    parseInt(fielder.position) - 1
                  ]
                ) {
                  playerIdsSeen[
                    parseInt(
                      play.playEvent.gameState.fielders[
                        parseInt(fielder.position) - 1
                      ]
                    )
                  ] = true
                }
              }
            }
            if (play.playEvent.runnerMovements) {
              for (var base in play.playEvent.runnerMovements) {
                var runner = play.playEvent.runnerMovements[base]
                if (runner && runner.movements) {
                  for (var k = 0; k < runner.movements.length; k++) {
                    var movement = runner.movements[k]
                    if (movement.scored) {
                      hasScoringPlay = true
                      if (
                        movement.runRulings &&
                        movement.runRulings.length > 0
                      ) {
                        for (var l = 0; l < movement.runRulings.length; l++) {
                          if (movement.runRulings[l] == 'UR') {
                            hasUnearnedRun = true
                          }
                          if (movement.runRulings[l] == 'NR') {
                            hasNoRbi = true
                          }
                        }
                      }
                    }
                    if (movement.reason) {
                      movementReasonsSeen[movement.reason] = true
                    }
                    if (movement.fielderCredits) {
                      for (var l = 0; l < movement.fielderCredits.length; l++) {
                        var movementFielder = movement.fielderCredits[l]
                        if (
                          play.playEvent.gameState &&
                          play.playEvent.gameState.fielders &&
                          play.playEvent.gameState.fielders[
                            parseInt(movementFielder.position) - 1
                          ]
                        ) {
                          playerIdsSeen[
                            parseInt(
                              play.playEvent.gameState.fielders[
                                parseInt(movementFielder.position) - 1
                              ]
                            )
                          ] = true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (play.type == 'sub') {
          if (play.defPos == 1) {
            hasPitchingSub = true
            hasDefensiveSub = true
          } else if (play.defPos == 11) {
            hasPinchHitter = true
          } else if (play.defPos == 12) {
            hasPinchRunner = true
          } else {
            hasDefensiveSub = true
          }
        } else if (play.type == 'eject') {
          hasEjection = true
        } else if (play.type === 'pitch' && play.pitchCode === 'VP') {
          hasPitcherPitchTimerViolation = true
        } else if (play.type === 'pitch' && play.pitchCode === 'VC') {
          hasCatcherPitchTimerViolation = true
        } else if (play.type === 'pitch' && play.pitchCode === 'VS') {
          hasDefensiveShiftViolation = true
        } else if (play.type === 'pitch' && play.pitchCode === 'AC') {
          hasBatterPitchTimerViolation = true
        } else if (play.type === 'pitch' && play.pitchCode === 'AB') {
          hasBatterTimeoutViolation = true
        } else if (play.type === 'CV') {
          hasMoundVisit = true
        } else if (play.type === 'BT') {
          hasBatterTimeout = true
        }
      }
    }

    var errorSeen = false

    var optionsSeen = {}

    for (var i = 0; i < PlayTypeOptions.length; i++) {
      if (!playTypesSeen[PlayTypeOptions[i].value]) {
        continue
      }
      if (
        PlayTypeOptions[i].value == PlayTypes.ERROR_BASERUNNING ||
        PlayTypeOptions[i].value == PlayTypes.ERROR
      ) {
        errorSeen = true
        continue
      }
      if (optionsSeen[PlayTypeOptions[i].value]) {
        continue
      }
      optionsSeen[PlayTypeOptions[i].value] = true
      options.push({
        id: PlayTypeOptions[i].value,
        code: PlayTypeOptions[i].label,
      })
    }
    for (var i = 0; i < BaseRunningReasonOptions.length; i++) {
      if (!movementReasonsSeen[BaseRunningReasonOptions[i].value]) {
        continue
      }
      if (BaseRunningReasonOptions[i].value == MovementReasons.ERROR) {
        errorSeen = true
        continue
      }
      if (optionsSeen[BaseRunningReasonOptions[i].value]) {
        continue
      }
      optionsSeen[BaseRunningReasonOptions[i].value] = true
      options.push({
        id: 'reason_' + BaseRunningReasonOptions[i].value,
        code: BaseRunningReasonOptions[i].label,
      })
    }

    if (errorSeen) {
      options.push({
        id: PlayTypes.ERROR,
        code: 'Error',
      })
    }

    for (var flag in flagsSeen) {
      var labelName = ''
      switch (flag) {
        case 'SF':
          labelName = 'Sac Fly'
          break
        case 'SAC':
          labelName = 'Sac Bunt'
          break
        case 'ITP':
          labelName = 'Inside The Park'
          break
        case 'GR':
          labelName = 'Ground Rule'
          break
        case 'IF':
          labelName = 'Infield Fly'
          break
        case 'NDP':
          labelName = 'Non Double Play'
          break
      }
      if (labelName) {
        options.push({
          id: 'flag_' + flag,
          code: labelName,
        })
      }
    }

    for (var playerId in playerIdsSeen) {
      var playerName = this.store.dware.getPlayerNameShortNoNumber(playerId)
      options.push({
        id: playerId,
        code: playerName,
      })
    }

    options.push({
      id: 'top_inning',
      code: this.awayTeamShortName,
    })
    options.push({
      id: 'bottom_inning',
      code: this.homeTeamShortName,
    })
    if (hasScoringPlay) {
      options.push({
        id: 'run_scoring_play',
        code: 'Run Scored',
      })
    }
    if (hasUnearnedRun) {
      options.push({
        id: 'unearned_run',
        code: 'Unearned Run',
      })
    }
    if (hasNoRbi) {
      options.push({
        id: 'no_rbi',
        code: 'No RBI',
      })
    }
    if (hasPitchingSub) {
      options.push({
        id: 'pitching_change',
        code: 'Pitching Change',
      })
    }
    if (hasPinchHitter) {
      options.push({
        id: 'pinch_hitter',
        code: 'Pinch Hitter',
      })
    }
    if (hasPinchRunner) {
      options.push({
        id: 'pinch_runner',
        code: 'Pinch Runner',
      })
    }
    if (hasDefensiveSub) {
      options.push({
        id: 'defensive_sub',
        code: 'Defensive Sub',
      })
    }
    if (hasEjection) {
      options.push({
        id: 'ejection',
        code: 'Ejection',
      })
    }
    if (hasPitcherPitchTimerViolation) {
      options.push({
        id: 'pitcher_pitch_timer_violation',
        code: 'Pitcher Pitch Timer Violation',
      })
    }
    if (hasCatcherPitchTimerViolation) {
      options.push({
        id: 'catcher_pitch_timer_violation',
        code: 'Catcher Pitch Timer Violation',
      })
    }
    if (hasDefensiveShiftViolation) {
      options.push({
        id: 'defensive_shift_violation',
        code: 'Defensive Shift Violation',
      })
    }
    if (hasBatterPitchTimerViolation) {
      options.push({
        id: 'batter_pitch_timer_violation',
        code: 'Batter Pitch Timer Violation',
      })
    }
    if (hasBatterTimeoutViolation) {
      options.push({
        id: 'batter_timeout_violation',
        code: 'Batter Timeout Violation',
      })
    }
    if (hasMoundVisit) {
      options.push({
        id: 'mound_visit',
        code: 'Mound Visit',
      })
    }
    if (hasBatterTimeout) {
      options.push({
        id: 'batter_timeout',
        code: 'Batter Timeout',
      })
    }

    return options
  }

  @action resetLineups() {
    // only apply for final games
    if (!this.stringerData.isFinal) {
      //  return;
    }

    var awayStartingLineup = toJS(this.stringerData.gameStateInfo.awayLineup)
    var homeStartingLineup = toJS(this.stringerData.gameStateInfo.homeLineup)

    var awayLineup = []
    var awayHasDH = false

    for (var i = 0; i < awayStartingLineup.length; i++) {
      var player = awayStartingLineup[i]
      if (player.position == 10) {
        awayHasDH = true
      }
      awayLineup.push({
        player: player.id,
        position: player.position,
        isInLineup: player.isInLineup,
        isEnabled: player.isEnabled,
      })
    }

    var homeLineup = []
    var homeHasDH = false
    for (var i = 0; i < homeStartingLineup.length; i++) {
      var player = homeStartingLineup[i]
      if (player.position == 10) {
        homeHasDH = true
      }
      homeLineup.push({
        player: player.id,
        position: player.position,
        isInLineup: player.isInLineup,
        isEnabled: player.isEnabled,
      })
    }

    var runners = [null, null, null, null]

    var startOfGameState = {
      homeDH: homeHasDH,
      awayDH: awayHasDH,
      homeLineup: homeLineup,
      awayLineup: awayLineup,
      batOrder: 0,
      batter: awayLineup[0].player,
      team: '0',
      runners: runners,
      fielders: [],
      balls: 0,
      strikes: 0,
      outs: 0,
    }

    var gameRunners = {}
    var numGameOuts = 0

    var atBats = this.stringerData.atBats

    var inningNum = 1
    var isTopInning = true
    for (var i = 0; i < atBats.length; i++) {
      let atBat = atBats[i]
      let plays = atBat.plays ? atBat.plays : []

      atBat.inning = inningNum
      atBat.topInningSw = isTopInning ? 'Y' : 'N'

      for (var j = 0; j < plays.length; j++) {
        let play = plays[j]

        if (this.isFlexibleRules) {
          if (i == 0 && j == 0) {
            var gameState = toJS(play.gameState)
            if (gameState && gameState.awayLineup) {
              for (var k = 0; k < gameState.awayLineup.length; k++) {
                startOfGameState.awayLineup[k].isEnabled =
                  gameState.awayLineup[k].isEnabled
                startOfGameState.awayLineup[k].isInLineup =
                  gameState.awayLineup[k].isInLineup
              }
            }
            if (gameState && gameState.homeLineup) {
              for (var k = 0; k < gameState.homeLineup.length; k++) {
                startOfGameState.homeLineup[k].isEnabled =
                  gameState.homeLineup[k].isEnabled
                startOfGameState.homeLineup[k].isInLineup =
                  gameState.homeLineup[k].isInLineup
              }
            }
          }
        }

        runners[1] = gameRunners[1]
        runners[2] = gameRunners[2]
        runners[3] = gameRunners[3]

        if (
          (play.type == 'play' || play.type == 'result') &&
          play.playEvent &&
          play.playEvent.runnerMovements
        ) {
          var runnerMovements = Object.assign(
            {},
            toJS(play.playEvent.runnerMovements)
          )

          if (
            play.playEvent.playType == PlayTypes.STRIKEOUT &&
            !runnerMovements['B']
          ) {
            numGameOuts++
          }

          gameRunners[0] = startOfGameState.batter

          // put in runner movements that are missing
          for (var k = 1; k <= 3; k++) {
            if (gameRunners[k] && !runnerMovements[k + '']) {
              runnerMovements[k + ''] = {
                start: k + '',
                end: k + '',
                movements: [],
              }
            } else if (!gameRunners[k] && runnerMovements[k + '']) {
              delete runnerMovements[k + '']
            }
          }

          var newRunners = {}
          for (var movementBase in runnerMovements) {
            var runner = runnerMovements[movementBase]
            var runnerIsOut = false
            var runnerScored = false
            if (runner.movements) {
              for (var k = 0; k < runner.movements.length; k++) {
                var movement = runner.movements[k]
                if (movement.out) {
                  runnerIsOut = true
                  numGameOuts++
                } else if (movement.scored) {
                  runnerScored = true
                }
              }
            }
            if (
              !runnerIsOut &&
              !runnerScored &&
              (runner.end == 1 || runner.end == 2 || runner.end == 3)
            ) {
              var runnerStart = runner.start
              if (!runnerStart || runnerStart == 'B') {
                runnerStart = 0
              }
              newRunners[runner.end] = gameRunners[runnerStart]
            }
          }
          gameRunners = newRunners
          if (numGameOuts >= 3) {
            numGameOuts = 0
            gameRunners = {}
            if (!isTopInning) {
              inningNum += 1
            }
            isTopInning = !isTopInning
          }
        } else if (play.type == 'runner' && play.base) {
          gameRunners[play.base] = play.playerId || play.player
        }

        var gameState = toJS(play.gameState)
        if (gameState) {
          if (gameState.batOrder == -1) {
            // if the bat order is unknown, attempt to reconcile by finding a matching player
            if (gameState.team == 0) {
              for (var k = 0; k < gameState.awayLineup.length; k++) {
                if (
                  gameState.awayLineup[k] &&
                  gameState.awayLineup[k].player == gameState.batter
                ) {
                  gameState.batOrder = k
                  break
                }
              }
            } else {
              for (var k = 0; k < gameState.homeLineup.length; k++) {
                if (
                  gameState.homeLineup[k] &&
                  gameState.homeLineup[k].player == gameState.batter
                ) {
                  gameState.batOrder = k
                  break
                }
              }
            }
          }
          startOfGameState.batOrder = gameState.batOrder
          gameState.runners = Object.assign([], startOfGameState.runners)
          gameState.awayDH = startOfGameState.awayDH
          gameState.awayLineup = Object.assign([], startOfGameState.awayLineup)
          gameState.homeDH = startOfGameState.homeDH
          gameState.homeLineup = Object.assign([], startOfGameState.homeLineup)
          gameState.batter =
            gameState.team == 0
              ? gameState.awayLineup[gameState.batOrder].player
              : gameState.homeLineup[gameState.batOrder].player
          startOfGameState.batter = gameState.batter
          startOfGameState.team = gameState.team
        }

        if (!gameState) {
          gameState = Object.assign({}, startOfGameState)
        }

        play.gameState = gameState
        if (play.type == 'sub') {
          var useLineup = []
          if (play.subTeam == 0 || play.team == 0) {
            useLineup = startOfGameState.awayLineup
          } else {
            useLineup = startOfGameState.homeLineup
          }
          if (play.defPos == 12) {
            for (var k = 1; k <= 3; k++) {
              if (runners[k] == useLineup[parseInt(play.batPos) - 1].player) {
                gameRunners[k] = play.subPlayer || play.player
                runners[k] = gameRunners[k]
              }
            }
          }
          if (this.isFlexibleRules) {
            if (parseInt(play.batPos) > 0) {
              useLineup[parseInt(play.batPos) - 1].player =
                play.subPlayer || play.player
              useLineup[parseInt(play.batPos) - 1].position = play.defPos
            }
          } else {
            if (play.batPos == 0) {
              useLineup[useLineup.length - 1].player =
                play.subPlayer || play.player
              useLineup[useLineup.length - 1].position = play.defPos
            } else {
              useLineup[parseInt(play.batPos) - 1].player =
                play.subPlayer || play.player
              useLineup[parseInt(play.batPos) - 1].position = play.defPos
            }
          }
        } else if (play.type == 'runner') {
          runners[parseInt(play.base)] = play.playerId || play.player
        }
      }
    }
  }

  @computed get atBatCount() {
    return this._atBats.length
  }

  @computed get playCount() {
    return this._atBats.reduce((sum, atBat) => {
      let count = atBat.plays ? atBat.plays.length : 0

      return sum + count
    }, 0)
  }

  @computed get resolvedAtBatMap() {
    let map = []

    for (var i = 0; i < this.atBats.length; i++) {
      let atBat = this.atBats[i]
      if (atBat.type != 'result') {
        continue
      }

      let plays = atBat.plays || []

      map[atBat.atBatIdx] = false

      if (
        plays
          .filter((play) => play.numberOfMetricErrors)
          .every((play) => this.resolvedPlayMap[play.playId])
      ) {
        map[atBat.atBatIdx] = true
      }
    }

    return map
  }

  @computed get positions() {
    return this.stringerData.positions || []
  }

  @computed get positionMap() {
    let map = []

    for (var i = 0; i < this.positions.length; i++) {
      let position = this.positions[i]
      map[position.value] = position.label
    }
  }

  @computed get lastAtBat() {
    if (!this.atBats.length) {
      return null
    }

    return this.atBats[this.atBats.length - 1]
  }

  @computed get lastPlay() {
    if (
      !this.lastAtBat ||
      !this.lastAtBat.plays ||
      !this.lastAtBat.plays.length
    ) {
      return null
    }

    return this.lastAtBat.plays[this.lastAtBat.plays.length - 1]
  }

  @action togglePlayerPositionalData(playerId, isHome) {
    let { teams = {} } = this.boxscore

    let playerTeam = isHome ? teams.home : teams.away
    let player = playerTeam.players[playerId] || {}
    player.isExpanded = player.isExpanded ? !player.isExpanded : true
  }

  // scoresheet approval

  @observable linescoreApproval = false
  @observable awayBattingApproval = false
  @observable awayOffensiveSubstitutionsApproval = false
  @observable awayNotesApproval = false
  @observable awayPitchingApproval = false
  @observable homeBattingApproval = false
  @observable homeOffensiveSubstitutionsApproval = false
  @observable homeNotesApproval = false
  @observable homePitchingApproval = false
  @observable umpiresApproval = false
  @observable gameInfoApproval = false
  @observable reviewsEjectionsApproval = false
  @observable automaticRunnerApproval = false

  @computed get confirmed() {
    let confirmed = {
      Linescore: this.linescoreApproval,
      AwayBatting: this.awayBattingApproval,
      AwayOffensiveSubstitutions: this.awayOffensiveSubstitutionsApproval,
      AwayNotes: this.awayNotesApproval,
      AwayPitching: this.awayPitchingApproval,
      HomeBatting: this.homeBattingApproval,
      HomeOffensiveSubstitutions: this.homeOffensiveSubstitutionsApproval,
      HomeNotes: this.homeNotesApproval,
      HomePitching: this.homePitchingApproval,
      Umpires: this.umpiresApproval,
      GameInfo: this.gameInfoApproval,
      ReviewsEjections: this.reviewsEjectionsApproval,
      AutomaticRunner: this.automaticRunnerApproval,
    }
    return confirmed
  }
  @computed get approved() {
    if (!this.approvalData.approvedStatus) {
      for (const section in this.confirmed) {
        if (this.confirmed[section] === false) {
          return false
        }
      }
    }
    return true
  }

  @observable alreadyApproved = false

  @action setApprovalData() {
    this.alreadyApproved = this.approvalData.approvedStatus
      ? this.approvalData.approvedStatus
      : false
    if (this.approvalData.approvedStatus) {
      this.linescoreApproval = true
      this.awayBattingApproval = true
      this.awayOffensiveSubstitutionsApproval = true
      this.awayNotesApproval = true
      this.awayPitchingApproval = true
      this.homeBattingApproval = true
      this.homeOffensiveSubstitutionsApproval = true
      this.homeNotesApproval = true
      this.homePitchingApproval = true
      this.umpiresApproval = true
      this.gameInfoApproval = true
      this.reviewsEjectionsApproval = true
      this.automaticRunnerApproval = true
    }
  }

  @action resetApprovedData() {
    this.linescoreApproval = false
    this.awayBattingApproval = false
    this.awayOffensiveSubstitutionsApproval = false
    this.awayNotesApproval = false
    this.awayPitchingApproval = false
    this.homeBattingApproval = false
    this.homeOffensiveSubstitutionsApproval = false
    this.homeNotesApproval = false
    this.homePitchingApproval = false
    this.umpiresApproval = false
    this.gameInfoApproval = false
    this.reviewsEjectionsApproval = false
    this.automaticRunnerApproval = false
  }

  refreshBacklog() {
    this.isLoading = true

    return fetch({
      url: `/api/games/${this.store.gamePk}/backlog`,
    })
      .then((backlog) => {
        this.backlog.replace(backlog)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  initialize() {
    this.load()
    const id = this.store.gamePk ? this.store.gamePk : this.store.eventId

    this.authReaction = reaction(
      () => ({
        authenticated: this.store.auth.isAuthenticated,
      }),
      ({ authenticated }) => {
        if (authenticated) {
          this.load()
        }
      }
    )

    this.gamePkReaction = reaction(
      () => ({
        gamePk: id,
      }),
      ({ gamePk }) => {
        this.reset()

        if (gamePk) {
          this.load()
        }
      }
    )

    this.pageReaction = reaction(
      () => ({
        page: this.page,
      }),
      ({ page }) => {
        this.reset(true)
        if (page && (this.store.gamePk || this.store.eventId)) {
          this.load()
        }
      }
    )

    this.playCountReaction = reaction(
      () => this.playCount,
      (count) => {
        if (this.isLiveMode) {
          // this.setLastPlay()
        }
      }
    )

    this.__isLockedReaction = reaction(
      () => this.__isLocked,
      (locked) => {
        if (locked) {
          this.unlock()
        }
      }
    )

    this.replaysReaction = reaction(
      () => ({
        replayParams: this.replayParams,
      }),
      () => {
        this.fetchReplays()
      }
    )
  }
}
