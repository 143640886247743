import {
  observable,
  computed,
  action,
  transaction,
  reaction,
  toJS,
  spy,
  extendObservable,
  extras,
} from 'mobx'
import axios, { CancelToken } from 'axios'
import { NotificationTypes } from 'constants'
import { get } from 'lodash'

let source = CancelToken.source()
const fetch = (options) =>
  axios(
    Object.assign({}, options, {
      cancelToken: source.token,
    })
  ).then((response) => response.data)

export default class PitchModalStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  /**
   * edit pitch data
   */

  @observable loading = false
  @observable playId = null

  @computed get isActive() {
    if (!this.playId) {
      return false
    }

    if (!this.store.game.pitchMap[this.playId]) {
      return false
    }

    return true
  }

  @computed get playData() {
    return this.store.game.pitchMap[this.playId] || {}
  }

  @observable _playData = {}

  @action set(key, value) {
    if (key == 'gameModeId') {
      this._playData.gameMode.id = value

      if (value == 0) {
        this._playData.atBatNumber = 1
        this._playData.pitchNumber = 0
        this._playData.pickoffNumber = 0
      }

      if (value == 1) {
        this._playData.pitchNumber = 0
        this._playData.pickoffNumber = 0
      }
    } else {
      this._playData[key] = value
    }
  }

  @action show(playId) {
    this.playId = playId
  }

  @action hide() {
    this.playId = null
  }

  @computed get videoSrc() {
    let guid = this.store.game.guids.find((guid) => guid.playId == this.playId)

    if (guid) {
      let videos = get(guid, 'videos', [])
      if (videos.length) {
        return get(videos[0], 'playbackUrl', null)
      }
    }

    return null
  }

  @computed get canSave() {
    let {
      inning,
      atBatNumber,
      pitchNumber,
      pickoffNumber,
      gameMode = {},
      isTopInning,
    } = this.playData

    let { id: gameModeId } = gameMode

    let {
      inning: _inning,
      atBatNumber: _atBatNumber,
      pitchNumber: _pitchNumber,
      pickoffNumber: _pickoffNumber,
      gameMode: _gameMode = {},
      isTopInning: _isTopInning,
    } = this._playData

    let { id: _gameModeId } = _gameMode

    if (inning != _inning) {
      return true
    }

    if (atBatNumber != _atBatNumber) {
      return true
    }

    if (pitchNumber != _pitchNumber) {
      return true
    }

    if (pickoffNumber != _pickoffNumber) {
      return true
    }

    if (gameModeId != _gameModeId) {
      return true
    }

    if (isTopInning != _isTopInning) {
      return true
    }

    return true
  }

  @action save() {
    let {
      inning,
      atBatNumber,
      pitchNumber,
      pickoffNumber,
      gameMode = {},
      isTopInning: topInning,
      playId,
    } = this._playData

    let { id: mlbGameMode } = gameMode

    this.loading = true
    this.hide()

    return fetch({
      method: 'POST',
      url: '/api/audit/meta',
      data: {
        gamePk: this.store.gamePk,
        audits: [
          {
            inning,
            atBatNumber,
            pitchNumber,
            pickoffNumber,
            mlbGameMode,
            topInning,
            playId,
            isLiveGame: !this.store.game.isFinal,
          },
        ],
      },
    })
      .then((data) => {
        let gameMode = {
          id: mlbGameMode,
          name:
            mlbGameMode == 2
              ? 'Live'
              : mlbGameMode == 1
              ? 'Warm Up'
              : 'Batting Practice',
        }

        for (var i = 0; i < this.store.game.guids.length; i++) {
          let guid = this.store.game.guids[i]

          if (guid.guid == playId) {
            Object.assign(guid, {
              inning,
              atBatNumber,
              pitchNumber,
              pickoffNumber,
              isTopInning: topInning,
              gameMode,
            })
          }
        }

        this.loading = false
        this.store.notifications.trigger(
          NotificationTypes.PITCH_META_SUCCESS,
          2
        )
      })
      .catch((error) => {
        console.error(error)
        this.loading = false
        this.store.notifications.trigger(NotificationTypes.PITCH_META_ERROR, 2)
      })
  }

  initialize() {
    this.playDataReaction = reaction(
      () => this.playData,
      (data) => {
        let _data = {}

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            _data[key] = data[key]
          }
        }

        extendObservable(this._playData, _data)
      }
    )
  }
}
