import PropTypes from 'prop-types'
import React from 'react'

export const DiagramCard = (props) => {
  let { title = 'Diagram' } = props

  return (
    <div className='card mb-4'>
      <header className='card-header' style={{ backgroundColor: 'whitesmoke' }}>
        <p className='card-header-title has-text-centered'>{title}</p>
      </header>
      <div className='card-content'>
        <DiagramCardContent {...props} />
      </div>
    </div>
  )
}

export const DiagramCardContent = (props) => {
  return (
    <div
      style={{ width: '100%' }}
      dangerouslySetInnerHTML={{ __html: props.src }}
    />
  )
}

DiagramCard.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
}
