import axios from 'axios'
import { action, computed, observable } from 'mobx/lib/mobx'
import { reaction } from 'mobx'

const CancelToken = axios.CancelToken
let source = CancelToken.source()

const fetch = (options) =>
  axios(
    Object.assign({}, options, {
      cancelToken: source.token,
    })
  ).then((response) => response.data)

export default class OSCardStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  initialize() {
    if (this.shouldFetch) {
      this.reload()
    }
    this.shouldFetchReaction = reaction(
      () => this.shouldFetch,
      (shouldFetch) => {
        if (shouldFetch) {
          this.reload()
        }
      }
    )

    this.playIdReaction = reaction(
      () => this.playId,
      (playId) => {
        if (playId) {
          this.reload()
        }
      }
    )

    this.isPlayAtThirdBaseReation = reaction(
      () => this.isPlayAtThirdBase,
      (trackedEvents) => {
        if (trackedEvents) {
          this.fetchHitVsError()
        }
      }
    )
  }

  @computed get tab() {
    return this.store.hash.playTab
  }

  @computed get playId() {
    return this.store.play.playId
  }

  @computed get isMLBGame() {
    return this.store.game.sportId === 1
  }

  @computed get shouldFetch() {
    return (
      this.store.auth.isAuthenticated &&
      this.tab === 'os' &&
      ((!!this.playId && !!this.store.gamePk) || !!this.store.hash.playId)
    )
  }

  @observable hitVsErrorLoading = false
  @observable hitVsErrorProbability = {}
  @observable wildPitchProbability = {}
  @observable wildPitchLoading = false

  @computed get wildPitchProbabilityCalculated() {
    return this.wildPitchProbability?.jsonData?.prediction
      ? (this.wildPitchProbability?.jsonData?.prediction * 100).toFixed(2) + '%'
      : 'N/A'
  }

  @computed get passedBallProbabilityCalculated() {
    return this.wildPitchProbabilityCalculated.includes('%')
      ? (100 - this.wildPitchProbabilityCalculated.slice(0, -1)).toFixed(2) +
          '%'
      : 'N/A'
  }

  @computed get hitProbabilityCalculated() {
    return this.hitVsErrorProbability?.summaryPct
      ? (100 - this.hitVsErrorProbability?.summaryPct * 100).toFixed(2) + '%'
      : 'N/A'
  }

  @computed get errorProbabilityCalculated() {
    return this.hitVsErrorProbability?.summaryPct
      ? (this.hitVsErrorProbability?.summaryPct * 100).toFixed(2) + '%'
      : 'N/A'
  }
  @action async fetchWildPitchProbability() {
    this.wildPitchLoading = true
    return fetch({
      url: '/api/plays/wildPitchProbability',
      cancelToken: source.token,
      params: {
        gamePk: this.store.gamePk,
        playId: this.playId,
      },
    })
      .then((data) => {
        this.wildPitchProbability = data
        this.wildPitchLoading = false
      })
      .catch((error) => {
        this.wildPitchLoading = false
        console.error(error)
      })
  }

  @computed get isPlayAtThirdBase() {
    const { data } = this.store.play
    const { trackedEvents } = data
    if (trackedEvents) {
      for (const event of trackedEvents) {
        if (
          (event?.playEvent === 'BALL_WAS_DEFLECTED' ||
            event?.playEvent === 'BALL_WAS_FIELDED') &&
          event?.positionId === 5
        ) {
          return true
        }
      }
    }
    return false
  }
  @computed get isPlayHit() {
    const { data } = this.store.play
    const { pitchData } = data
    return !!(
      pitchData &&
      pitchData?.eventTypeCode &&
      pitchData.eventTypeCode === 'D'
    )
  }

  @computed get shouldShowHitVsError() {
    return this.shouldFetchHitVsError && this.hitVsErrorProbability !== {}
  }

  @computed get shouldFetchHitVsError() {
    return (
      this.isPlayAtThirdBase &&
      this.isPlayHit &&
      !this.store.play.runnersOn &&
      this.isMLBGame
    )
  }

  @action fetchHitVsError() {
    if (this.shouldFetchHitVsError) {
      this.hitVsErrorLoading = true
      return fetch({
        url: '/api/plays/hitVsErrorProbability',
        cancelToken: source.token,
        params: {
          gamePk: this.store.gamePk,
          playId: this.playId,
        },
      })
        .then((data) => {
          if (data?.jsonData?.predictStatus === 'Success') {
            if (data?.jsonData?.output) {
              this.hitVsErrorProbability = data.jsonData.output
            }
          }
          this.hitVsErrorLoading = false
        })
        .catch((error) => {
          this.hitVsErrorLoading = false
          console.error(error)
        })
    }
  }

  @action reload() {
    if (this.shouldFetch) {
      source.cancel()
      source = CancelToken.source()
      this.fetchWildPitchProbability()
      this.fetchHitVsError()
    }
  }
}
