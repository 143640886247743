import axios from 'axios'
import { action, computed, observable } from 'mobx'
import { OktaRole, Role } from 'shared/constants'
import uuid from 'uuid'

const {
  ADMIN,
  SUPER_USER,
  POWER_USER,
  DATA_QUALITY_USER,
  GAMEDAY_USER,
  USER,
  TRACKING_USER,
} = Role

const fetch = (options) => axios(options).then((r) => r.data)

/*
 *   Authentication State
 */

export default class AuthStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable user = null

  @computed get username() {
    return this.user ? this.user.username : null
  }

  @computed get role() {
    return this.user ? this.user.role : null
  }

  @computed get groups() {
    return this.user ? this.user.groups : []
  }

  @computed get isOkta() {
    return this.user ? this.user.isOkta : false
  }

  @computed get isAuthenticated() {
    return !!this.role
  }

  @computed get isOktaAdmin() {
    return this.role === Role.ADMIN && this.isOkta
  }

  @computed get isAdmin() {
    return this.role === Role.ADMIN
  }

  @computed get isSuperUser() {
    return [ADMIN, SUPER_USER].includes(this.role)
  }

  @computed get isPowerUser() {
    return [ADMIN, SUPER_USER, POWER_USER].includes(this.role)
  }

  @computed get hasGameLockPermission() {
    return (
      this.groups.includes(OktaRole.GAME_LOCK) ||
      this.groups.includes(OktaRole.ADMIN)
    )
  }

  @computed get hasReloadGameRedisPermission() {
    return (
      this.groups.includes(OktaRole.SUPER_USER) ||
      this.groups.includes(OktaRole.ADMIN)
    )
  }

  @computed get hasClearGameStatsPermission() {
    return (
      this.groups.includes(OktaRole.CLEAR_GAME_STATS) ||
      this.groups.includes(OktaRole.ADMIN)
    )
  }

  @computed get hasPlayBuilderReplayPermission() {
    return (
      this.groups.includes(OktaRole.SUPER_USER) ||
      this.groups.includes(OktaRole.ADMIN)
    )
  }

  @computed get hasTrackingTabPermission() {
    return this.role !== USER || this.groups.includes(OktaRole.HAWKEYE_USER)
  }

  @computed get hasPlayEventEditingPermission() {
    return this.isSuperUser || this.groups.includes(OktaRole.TRACKING)
  }

  // requests

  @observable _requests = {}
  @computed get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  @action getSession() {
    let id = uuid()

    this._requests[id] = true

    fetch({
      url: '/api/session',
    })
      .then(
        action((user) => {
          if (user && user.role) {
            this.user = user
          }
        })
      )
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  @action login(data) {
    let id = uuid()
    this._requests[id] = true

    fetch({
      url: '/login',
      method: 'POST',
      data: data,
    })
      .then(
        action((user) => {
          if (user && user.role) {
            this.user = user
            this.store.router.push(this.store.referrer)
          }
        })
      )
      .catch(
        action((error) => {
          this.showAlert()
          console.error(error)
        })
      )
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  @action logout() {
    let id = uuid()
    this._requests[id] = true

    fetch({
      url: '/logout',
      method: 'POST',
      data: {},
    })
      .then(
        action((user) => {
          this.user = user
        })
      )
      .finally(
        action(() => {
          delete this._requests[id]
        })
      )
  }

  @observable alert = false

  @action showAlert() {
    this.alert = true
  }

  @action hideAlert() {
    this.alert = false
  }

  @action toggleAlert() {
    this.alert = !this.alert
  }

  initialize() {}
}
