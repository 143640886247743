import cn from 'classnames'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'

const MetricErrors = ({ metricErrors, backlog, playId, atBatIdx }) => {
  return (
    <div className='card mb-4'>
      <header className='card-header' style={{ backgroundColor: 'whitesmoke' }}>
        <p className='card-header-title has-text-centered'>Metric Errors</p>
      </header>
      <MetricErrorsTable metricErrors={metricErrors} />
      <OperatorFeedback backlog={backlog} playId={playId} atBatIdx={atBatIdx} />
    </div>
  )
}

export default MetricErrors

const MetricErrorsTable = ({ metricErrors }) => {
  if (!metricErrors || !metricErrors.length) {
    return (
      <div className='has-text-centered'>
        <p className='subtitle my-3'>No Errors for this play.</p>
      </div>
    )
  }

  return (
    <table className='table is-small is-fullwidth is-bordered'>
      <thead>
        <tr>
          <th>Time</th>
          <th>Error</th>
        </tr>
      </thead>
      <tbody>
        {metricErrors.map((metricError, i) => {
          const { notes, timestamp } = metricError
          const formattedTimestamp = timestamp
            ? moment.utc(timestamp).local().format('hh:mm:ss.SSS A')
            : '--'

          return (
            <tr key={i}>
              <td style={{ width: '10%', verticalAlign: 'middle' }}>
                {formattedTimestamp}
              </td>
              <td>{notes || <span>&nbsp;</span>}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const IssueStatus = {
  DELETED: 'DELETED',
  OPEN: 'OPEN',
  RESOLVED: 'RESOLVED',
  SCRUBBED: 'SCRUBBED',
}

@inject('store')
@observer
class OperatorFeedback extends Component {
  constructor(props) {
    super(props)
    this.store = props.store
  }

  state = {
    message: '',
  }

  scrub = () => {
    let node = findDOMNode(this.input)

    if (node) {
      let { value, placeholder } = node

      let message = value || placeholder

      this.store.actions.createScrub(this.props.playId, message)
      this.setState({ message: '' })
    }
  }

  updateScrubStatus = (id, status) => {
    this.store.actions.updateScrub(id, status)
  }

  resolve = () => {
    this.store.actions.resolvePlay(this.props.playId)
  }

  resolveAtBat = () => {
    this.store.actions.resolveAtBat(this.props.atBatIdx)
  }

  onMessage = (e) => {
    e.preventDefault()

    this.setState({
      message: e.target.value,
    })
  }

  render() {
    const { playId, backlog = [] } = this.props

    let { allPlays = [] } = this.store.game

    const play = allPlays.find((play) => play.playId == playId)

    if (!play) {
      return null
    }

    const { inning, atBatIdx, isPickoff, realPitchNum, pickoffNumber } = play

    let placeholder = `Please take a look at Inning: ${inning}, At Bat: ${
      atBatIdx + 1
    }`

    if (isPickoff) {
      placeholder += `, Pickoff: ${pickoffNumber}`
    } else {
      placeholder += `, Pitch: ${realPitchNum}`
    }

    const isResolved = this.store.game.resolvedPlayMap[playId]
    const atBat = this.store.game.atBats.find(
      (atBat) => atBat.atBatIdx == atBatIdx && atBat.type == 'result'
    )
    const atBatResolved = atBat.plays.every(
      (play) =>
        !play.numberOfMetricErrors ||
        this.store.game.resolvedPlayMap[play.playId]
    )

    return (
      <div className='card-content'>
        <label className='label'>Scrub Message</label>
        <textarea
          className='textarea mb-3'
          ref={(r) => (this.input = r)}
          onChange={this.onMessage}
          value={this.state.message}
          placeholder={placeholder}
        ></textarea>
        <div className='field is-grouped is-grouped-centered'>
          <p className='control'>
            <button
              className='button is-small'
              disabled={!this.props.playId}
              onClick={this.scrub}
            >
              Send to Scrubber
            </button>
          </p>
          <p className='control'>
            <button
              className='button is-small'
              disabled={!this.props.playId}
              onClick={this.resolve}
            >
              {isResolved ? 'Re-open Play' : 'Resolve Play'}
            </button>
          </p>
          <p className='control'>
            <button
              className='button is-small'
              disabled={!this.props.atBatIdx && this.props.atBatIdx != 0}
              onClick={this.resolveAtBat}
            >
              {atBatResolved ? 'Re-open At Bat' : 'Resolve At Bat'}
            </button>
          </p>
        </div>
        <div className='card mt-3' style={{ backgroundColor: 'whitesmoke' }}>
          <header className='card-header'>
            <p className='card-header-title has-text-centered'>
              Previous Scrubs
            </p>
          </header>
          <table className='table is-narrow is-fullwidth is-bordered'>
            <thead>
              <tr>
                <th>Created</th>
                <th>Created By</th>
                <th>Message</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {backlog
                .slice()
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((item) => {
                  const {
                    id,
                    createdBy,
                    createdAt,
                    message = '',
                    status = '',
                  } = item

                  return (
                    <tr key={createdAt}>
                      <td>
                        <time dateTime={createdAt}>
                          {moment(createdAt).format('M/DD hh:mmA')}
                        </time>
                      </td>
                      <td>{createdBy}</td>
                      <td>{message}</td>
                      <td>
                        <button
                          className={cn(
                            'button is-small',
                            status === IssueStatus.RESOLVED
                              ? 'is-success'
                              : 'is-warning'
                          )}
                          onClick={(e) => {
                            this.updateScrubStatus(
                              id,
                              [IssueStatus.SCRUBBED, IssueStatus.OPEN].includes(
                                status
                              )
                                ? IssueStatus.RESOLVED
                                : IssueStatus.OPEN
                            )
                          }}
                        >
                          {status}
                        </button>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
