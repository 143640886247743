import { action, computed } from 'mobx'

export default class HashStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @computed get date() {
    return this._map.date
  }

  @computed get orgIds() {
    return this._map.orgIds
  }

  @computed get sportId() {
    return this._map.sportId
  }

  @computed get leagueId() {
    return this._map.leagueId
  }

  @computed get atBatIdx() {
    return this._map.atBatIdx
  }

  @computed get playIdx() {
    return this._map.playIdx
  }

  @computed get playTab() {
    return this._map.playTab
  }

  @computed get playId() {
    return this._map.playId
  }

  @action set(key, value, isMulti) {
    if (isMulti) {
      return this._setMulti(key, value)
    }

    let map = {}

    for (let key in this._map) {
      map[key] = this._map[key]
    }

    if (value) {
      map[key] = value
    } else {
      delete map[key]
    }

    this._set(map)
  }

  @action _setMulti(key, value) {
    // this will handle merging a value into a key that accepts multiple values
    const selectedValues = (this[key] || '').split(',')
    let nextValues = []
    if (selectedValues.includes(value)) {
      nextValues = selectedValues.filter((v) => v != value)
    } else {
      nextValues = selectedValues.concat([value])
    }

    nextValues = nextValues.filter(Boolean).sort(this.collator.compare)
    this.set(key, nextValues.join(','))
  }

  @action delete(keyOrKeys) {
    if (Array.isArray(keyOrKeys)) {
      this.merge(
        keyOrKeys.reduce((map, key) => {
          map[key] = null
          return map
        }, {})
      )
    } else {
      this.set(keyOrKeys, null)
    }
  }

  @action reset(keys, excludeKeys) {
    if (keys) {
      this.delete(keys)
    } else if (excludeKeys) {
      const hash = excludeKeys
        .filter((key) => this[key])
        .map((key) => `${key}=${this[key]}`)
        .join(',')
      const path = hash.length ? `${this._path}#${hash}` : this._path
      this.store.router.push(path)
    } else {
      this.clear()
    }
  }

  @action clear() {
    this.store.router.push(this._path)
  }

  @action merge(map) {
    let _map = {}

    for (let key in this._map) {
      _map[key] = this._map[key]
    }

    for (let key in map) {
      if (map[key] || map[key] === 0) {
        _map[key] = map[key]
      } else {
        delete _map[key]
      }
    }

    this._set(_map)
  }

  // private actions

  @action _set(map) {
    let string = Object.keys(map)
      .map((key) => `${key}=${map[key]}`)
      .join('&')
    this.store.router.push(this._path + '#' + string)
  }

  @computed get string() {
    return this._string ? '#' + this._string : ''
  }

  // private properties

  @computed get _path() {
    return this.store.router.location
      ? this.store.router.location.pathname
      : '/'
  }

  @computed get _string() {
    return this.store.router.location
      ? this.store.router.location.hash.replace(/^#/, '')
      : ''
  }

  @computed get _map() {
    return this._string.split('&').reduce((map, str) => {
      try {
        const rgx = /^[\w\d]+\=/
        const match = str.match(rgx)

        if (match && match[0]) {
          const key = match[0].replace(/=/g, '')
          const value = str.replace(match[0], '')
          map[key] = value
        }
      } catch (e) {}

      return map
    }, {})
  }

  initialize() {
    this.collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  }
}
