import React from 'react'
import { inject, observer } from 'mobx-react'
import { toJS, action } from 'mobx'
import { get } from 'lodash'
import ImageLoader from 'components/ui/ImageLoader'
import CursorPosition from './CursorPosition'

const Hit = inject('store')(
  observer((props) => {
    const { venueId = 'generic' } = props.store.game

    const fieldX = get(
      props.store.edit.play,
      'playEvent.hitLocation.fieldX',
      -10
    )
    const fieldY = get(
      props.store.edit.play,
      'playEvent.hitLocation.fieldY',
      -10
    )
    const defaultField =
      'https://prod-gameday.mlbstatic.com/responsive-gameday-assets/1.2.0/images/fields/generic.svg'
    const field = `https://prod-gameday.mlbstatic.com/responsive-gameday-assets/1.2.0/images/fields/${venueId}.svg`

    const clickHandler = action(() => {
      const { x, y } = props

      let play = toJS(props.store.edit.play)

      play.playEvent = play.playEvent || {}
      play.playEvent.hitLocation = play.playEvent.hitLocation || {}
      play.playEvent.hitLocation.fieldX = x
      play.playEvent.hitLocation.fieldY = y

      props.store.edit.setPlay(play)
    })

    return (
      <div style={{ width: '255px', height: '255px' }} onClick={clickHandler}>
        <div style={{ position: 'absolute' }}>
          <ImageLoader width='255' src={field} fallback={defaultField} />
        </div>
        <div style={{ position: 'absolute' }}>
          <svg height='255px' width='255px'>
            <circle
              cx={fieldX}
              cy={fieldY}
              r='6'
              stroke='black'
              strokeWidth='1'
              fill='red'
            />
          </svg>
        </div>
      </div>
    )
  })
)

const HitLocationEditor = (props) => {
  return (
    <CursorPosition>
      <Hit />
    </CursorPosition>
  )
}

export default HitLocationEditor
