import { cloneDeep } from 'lodash'
import { diff } from 'deep-diff'
import { deepOmit } from './deepOmit'

export function diffs(oldData, newData, path) {
  const _oldData = deepOmit(cloneDeep(oldData), [...KEYS_TO_OMIT_FROM_DIFF])
  const _newData = deepOmit(cloneDeep(newData), [...KEYS_TO_OMIT_FROM_DIFF])

  let diffs = diff(_oldData, _newData, {
    normalize: (path, key, lhs, rhs) => {
      const ln = Number(lhs)
      const rn = Number(rhs)
      if (Number.isNaN(ln) || Number.isNaN(rn)) {
        return false
      }
      return [ln, rn]
    },
  })
  diffs = removeMeaninglessDiffs(diffs)
  diffs = summarizeDiffs(diffs, path)
  return diffs
}

const KEYS_TO_OMIT_FROM_DIFF = [
  'hasChange',
  'hasDelete',
  'isInvalid',
  'operatorChangeReasons',
  'updates',
]

function summarizeDiffs(diffs, basePath) {
  const _diffs = cloneDeep(diffs || [])
  return _diffs?.map((diff) => {
    const summaryObj = {
      ...diff,
      path: [basePath, ...diff.path].join('/'),
    }
    return summaryObj
  })
}

function removeMeaninglessDiffs(atBatDiffs) {
  const _atBatDiffs = cloneDeep(atBatDiffs || [])

  return _atBatDiffs?.filter(
    (atBatDiff) =>
      isSomething(atBatDiff.lhs) ||
      isSomething(atBatDiff.rhs) ||
      isSomething(atBatDiff.item)
  )
}

function isSomething(a) {
  if (Array.isArray(a)) {
    return a.length > 0
  }
  if (typeof a === 'object') {
    return a !== {} && a !== null
  }
  return !!a
}
