import merge from 'lodash/merge'
import { action, computed, observable } from 'mobx/lib/mobx'

export default class DWareUtil {
  constructor(store) {
    this.store = store
    this.playStringError = ''
  }

  @observable _showPlayerIds = false
  @observable _showAtBatStart = false

  @action togglePlayerIds() {
    this._showPlayerIds = !this._showPlayerIds
  }

  @action toggleAtBatStart() {
    this._showAtBatStart = !this._showAtBatStart
  }

  @computed get showPlayerIds() {
    return this._showPlayerIds
  }

  @computed get showAtBatStart() {
    return this._showAtBatStart
  }

  getGameUmpires = function (stringerData) {
    var umps = []

    if (
      stringerData &&
      stringerData.gameStateInfo &&
      stringerData.gameStateInfo.umpires
    ) {
      for (var i = 0; i < stringerData.gameStateInfo.umpires.length; i++) {
        var umpireObj = stringerData.gameStateInfo.umpires[i]
        if (!umpireObj.fullName) {
          umpireObj.fullName = this.getPlayerFullName(umpireObj.id)
        }
        umps.push(umpireObj)
      }
    }

    return umps
  }

  getWinningPitcher = function (stringerData) {
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('info,wp') != -1) {
        return parseInt(playString.split(',')[2])
      }
    }
    return null
  }

  getLosingPitcher = function (stringerData) {
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('info,lp') != -1) {
        return parseInt(playString.split(',')[2])
      }
    }
    return null
  }

  getSavePitcher = function (stringerData) {
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('info,save') != -1) {
        return parseInt(playString.split(',')[2])
      }
    }
    return null
  }

  getBlownSavePitchers = function (stringerData) {
    var blown = []
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('data,blownhold') != -1) {
        blown.push(parseInt(playString.split(',')[2]))
      }
    }
    return blown
  }

  getHoldPitchers = function (stringerData) {
    var holds = []
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('data,hold') != -1) {
        holds.push(parseInt(playString.split(',')[2]))
      }
    }
    return holds
  }

  getSubstitutions = function (stringerData) {
    var awaySubstitutions = []
    var homeSubstitutions = []
    var ret = {
      away: awaySubstitutions,
      home: homeSubstitutions,
    }
    this.executePlayStrings(stringerData, false)
    for (var i = 0; i < stringerData.atBats.length; i++) {
      var atBat = stringerData.atBats[i]
      var subPlayerIds = {}
      var subPlay = null
      var subObj = null
      var numPitches = 0
      for (var j = 0; j < atBat.plays.length; j++) {
        var play = atBat.plays[j]
        if (play.type == 'pitch') {
          if (!this.isNonPitch(play.pitchCode)) {
            numPitches++
          }
        }
        if (play.type == 'sub') {
          subPlay = play
          subObj = {
            numPitches: numPitches,
            atBat: i + 1,
            pitch: j + 1,
            subs: [],
          }
          subPlayerIds[play.subPlayer] = play
          var playersSeen = {}
          for (var k = 0; k < 10; k++) {
            var playerId = play.gameState.fielders[k]
            if (!playerId || playersSeen[playerId]) {
              continue // ignore the 10th spot for non DH
            }
            playersSeen[playerId] = true
            var position = play.gameState.positions[k]
            var posSplit = position.split('-')
            position = posSplit[posSplit.length - 1]
            var playerName = this.getPlayerNameShort(playerId)
            subObj.subs.push({
              id: playerId,
              name: playerName,
              pos: position,
              posNum: this.getPositionFromString(position) + '',
              isSub: subPlayerIds[playerId],
              play: subPlayerIds[playerId] ? subPlayerIds[playerId] : play,
            })
          }
        } else if (
          subPlay &&
          subObj &&
          play.type == 'play' &&
          play.eventCode != 'NP'
        ) {
          if (subPlay.subTeam == 1) {
            homeSubstitutions.push(subObj)
          } else {
            awaySubstitutions.push(subObj)
          }
          subObj = null
          subPlay = null
        }
      }
      if (subPlay && subObj) {
        if (subPlay.subTeam == 1) {
          homeSubstitutions.push(subObj)
        } else {
          awaySubstitutions.push(subObj)
        }
      }
    }
    return ret
  }

  searchSubs = function (stringerData, inning, atBatNumber, batter) {
    var substitutions = []
    this.executePlayStrings(stringerData, false)
    for (var i = 0; i < stringerData.atBats.length; i++) {
      var atBat = stringerData.atBats[i]
      if (inning && atBat.inning != inning) {
        continue
      }
      if (atBatNumber && i + 1 != atBatNumber) {
        continue
      }
      if (batter && atBat.batter != batter) {
        continue
      }
      for (var j = 0; j < atBat.plays.length; j++) {
        var play = atBat.plays[j]
        if (play.type == 'batter') {
          var subPlay = play
          var subObj = {
            atBat: i + 1,
            pitch: j + 1,
            teamName: atBat.topInningSw == 'Y' ? 'away' : 'home',
            subs: [],
          }
          var subObj2 = {
            atBat: i + 1,
            pitch: j + 1,
            teamName: atBat.topInningSw == 'Y' ? 'home' : 'away',
            subs: [],
          }
          substitutions.push(subObj)
          substitutions.push(subObj2)
          var playersSeen = {}
          for (var k = 0; k < 10; k++) {
            var playerId = play.gameState.fielders[k]
            if (!playerId || playersSeen[playerId]) {
              continue // ignore the 10th spot for non DH
            }
            playersSeen[playerId] = true
            var position = play.gameState.positions[k]
            var posSplit = position.split('-')
            position = posSplit[posSplit.length - 1]
            var playerName = this.getPlayerNameShort(playerId)
            subObj.subs.push({
              id: playerId,
              name: playerName,
              pos: position,
              posNum: this.getPositionFromString(position) + '',
              isSub: false,
              play: play,
              isSearch: true,
              team: atBat.topInningSw == 'Y' ? 0 : 1,
            })
          }

          playersSeen = {}
          for (var k = 0; k < 10; k++) {
            var playerId = play.gameState.batters[k]
            if (!playerId || playersSeen[playerId]) {
              continue // ignore the 10th spot for non DH
            }
            playersSeen[playerId] = true
            var position = play.gameState.batterPositions[k]
            var posSplit = position.split('-')
            position = posSplit[posSplit.length - 1]
            var playerName = this.getPlayerNameShort(playerId)
            subObj2.subs.push({
              id: playerId,
              name: playerName,
              pos: position,
              posNum: this.getPositionFromString(position) + '',
              isSub: false,
              play: play,
              isSearch: true,
              team: atBat.topInningSw == 'Y' ? 1 : 0,
            })
          }
        }
      }
    }
    return substitutions
  }

  getStartingUmpires = function (stringerData) {
    var umpires = [
      { pos: 'Home Plate' },
      { pos: '1st Base' },
      { pos: '2nd Base' },
      { pos: '3rd Base' },
      { pos: 'Left Field' },
      { pos: 'Right Field' },
    ]
    var umpIdMap = {
      umphome: 1,
      ump1b: 2,
      ump2b: 3,
      ump3b: 4,
      umplf: 5,
      umprf: 6,
    }
    var umpNameMap = {
      umphomename: 1,
      ump1bname: 2,
      ump2bname: 3,
      ump3bname: 4,
      umplfname: 5,
      umprfname: 6,
    }
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('info,') != -1) {
        var playStringSplit = playString.split(',')
        if (umpIdMap[playStringSplit[1]] || umpNameMap[playStringSplit[1]]) {
          var field =
            umpIdMap[playStringSplit[1]] || umpNameMap[playStringSplit[1]]
          field -= 1
          if (umpIdMap[playStringSplit[1]]) {
            umpires[field].id = parseInt(playStringSplit[2])
            umpires[field].playNumber = playStringObj.playNumber
            umpires[field].idField = playStringSplit[1]
          }
          if (umpNameMap[playStringSplit[1]]) {
            umpires[field].name = playStringSplit[2]
            umpires[field].nameField = playStringSplit[1]
            umpires[field].namePlayNumber = playStringObj.playNumber
          }
        }
      }
    }
    return umpires
  }

  getScorekeepers = function (stringerData) {
    var scorekeeperIdMap = {
      scorer: 1,
      translator: 2,
      offscorer: 3,
    }
    var scorekeeperNameMap = {
      scorername: 1,
      translatorname: 2,
      offscorername: 3,
    }
    var scorekeepers = [{}, {}, {}]
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('info,') != -1) {
        var playStringSplit = playString.split(',')
        if (
          scorekeeperIdMap[playStringSplit[1]] ||
          scorekeeperNameMap[playStringSplit[1]]
        ) {
          var field =
            scorekeeperIdMap[playStringSplit[1]] ||
            scorekeeperNameMap[playStringSplit[1]]
          field -= 1
          if (scorekeeperIdMap[playStringSplit[1]]) {
            scorekeepers[field].id = parseInt(playStringSplit[2])
            scorekeepers[field].playNumber = playStringObj.playNumber
            scorekeepers[field].idField = playStringSplit[1]
          }
          if (scorekeeperNameMap[playStringSplit[1]]) {
            scorekeepers[field].name = playStringSplit[2]
            scorekeepers[field].nameField = playStringSplit[1]
            scorekeepers[field].namePlayNumber = playStringObj.playNumber
          }
        }
      }
    }
    return scorekeepers
  }

  getInfoMap = function (stringerData) {
    var infoMap = {}
    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('info,') != -1) {
        var playStringSplit = playString.split(',')
        var infoObj = {
          value: playStringSplit[2],
          name: playStringSplit[1],
          playNumber: playStringObj.playNumber,
        }
        infoMap[playStringSplit[1]] = infoObj
        if (infoObj.name == 'timeofgame' || infoObj.name == 'timeofdelay') {
          if (infoObj.value) {
            var totalMinutes = parseInt(infoObj.value)
            if (!isNaN(totalMinutes)) {
              var hours = Math.round(totalMinutes / 60)
              var minutes = totalMinutes % 60
              infoObj.hours = hours
              infoObj.minutes = minutes
            }
          }
        }
      }
    }
    return infoMap
  }

  getStarters = function (stringerData, homeAway) {
    var teamNum = 0
    if (homeAway == 'home') {
      teamNum = 1
    }
    var starters = []

    for (var i = 0; i < stringerData.playStrings.length; i++) {
      var playStringObj = stringerData.playStrings[i]
      var playString = playStringObj.playStringFull
      if (playString.indexOf('start,') != -1) {
        var psSplit = playString.split(',')
        if (psSplit[3] == teamNum) {
          starters.push({
            playNumber: playStringObj.playNumber,
            nameFirstLast: psSplit[2].replace(/\"/g, ''),
            id: parseInt(psSplit[1]),
            pos: psSplit[5],
            batOrder: parseInt(psSplit[4]),
          })
        }
      }
    }

    for (var i = starters.length; i < 10; i++) {
      starters.push({
        id: null,
        pos: null,
        batOrder: null,
        playNumber: null,
        nameFirstLast: null,
      })
    }

    starters.sort(function (a, b) {
      if (!a.batOrder && b.batOrder) {
        return 1
      } else if (a.batOrder && !b.batOrder) {
        return -1
      }

      if (a.batOrder > b.batOrder) {
        return 1
      } else if (a.batOrder < b.batOrder) {
        return -1
      }
      return 0
    })

    return starters
  }

  getPositionFromString = function (stringVal) {
    var positions = {
      '1B': 3,
      '2B': 4,
      '3B': 5,
      SS: 6,
      LF: 7,
      CF: 8,
      RF: 9,
      C: 2,
      P: 1,
      PR: 12,
      DH: 10,
      PH: 11,
    }
    return positions[stringVal] || ''
  }

  getUmpirePositions = function () {
    return [
      { value: '0', label: 'Home Plate' },
      { value: '1', label: 'First Base' },
      { value: '2', label: 'Second Base' },
      { value: '3', label: 'Third Base' },
      { value: '4', label: 'Left Field' },
      { value: '5', label: 'Right Field' },
    ]
  }

  getUmpirePositionCodes = function () {
    return [
      { value: 'hp', label: 'Home Plate' },
      { value: '1b', label: 'First Base' },
      { value: '2b', label: 'Second Base' },
      { value: '3b', label: 'Third Base' },
      { value: 'lf', label: 'Left Field' },
      { value: 'rf', label: 'Right Field' },
    ]
  }

  getRunnerBases = function () {
    return [
      { value: '1', label: 'First Base' },
      { value: '2', label: 'Second Base' },
      { value: '3', label: 'Third Base' },
    ]
  }

  getInjury = function () {
    return [
      { value: 'Ankle', label: 'Ankle' },
      { value: 'Arm', label: 'Arm' },
      { value: 'Back', label: 'Back' },
      { value: 'Chest', label: 'Chest' },
      { value: 'Elbow', label: 'Elbow' },
      { value: 'Foot', label: 'Foot' },
      { value: 'Groin', label: 'Groin' },
      { value: 'Hand', label: 'Hand' },
      { value: 'Head', label: 'Head' },
      { value: 'Hip', label: 'Hip' },
      { value: 'Knee', label: 'Knee' },
      { value: 'Leg', label: 'Leg' },
      { value: 'Neck', label: 'Neck' },
      { value: 'Shoulder', label: 'Shoulder' },
      { value: 'Side', label: 'Side (oblique)' },
      { value: 'Wrist', label: 'Wrist' },
    ]
  }

  validate = function (stringerData) {
    this.playStringError = ''

    for (var i = 0; i < stringerData.atBats.length; i++) {
      var atBat = stringerData.atBats[i]
      var previousPlayEvent = null
      var playNumber = 0
      for (var j = 0; j < atBat.plays.length; j++) {
        var play = atBat.plays[j]
        if (play.type == 'play' || play.type == 'result') {
          playNumber++
          if (!previousPlayEvent || previousPlayEvent.type != 'pitch') {
            this.playStringError =
              'At Bat ' +
              (i + 1) +
              (play.type == 'result'
                ? ''
                : ', Baserunning event ' + playNumber) +
              ': Missing a preceding pitch, pickoff attempt, or non-pitch'
            return false
          }
        }
        previousPlayEvent = play

        if (
          play.updates &&
          play.updates.edit &&
          play.updates.edit.type == 'delete'
        ) {
          continue
        }
        if (play.type == 'play' || play.type == 'result') {
          var playEvent = play.playEvent
          if (
            playEvent.playType == 'stolen_base' ||
            playEvent.playType == 'caught_stealing' ||
            playEvent.playType == 'other_advance' ||
            playEvent.playType == 'defensive_indifference' ||
            playEvent.playType == 'wild_pitch' ||
            playEvent.playType == 'passed_ball' ||
            playEvent.playType == 'pickoff' ||
            playEvent.playType == 'os_pending_prior' ||
            playEvent.playType == 'pickoff_error' ||
            playEvent.playType == 'pickoff_caught_stealing'
          ) {
            var hasMatchingRunner = false
            for (var base in playEvent.runnerMovements) {
              var runner = playEvent.runnerMovements[base]
              if (runner.movements) {
                for (var k = 0; k < runner.movements.length; k++) {
                  var movement = runner.movements[k]
                  if (movement.reason == playEvent.playType) {
                    hasMatchingRunner = true
                    break
                  }
                }
              }
            }
            if (!hasMatchingRunner) {
              this.playStringError =
                'At Bat ' +
                (i + 1) +
                (play.type == 'result'
                  ? ''
                  : ', Baserunning event ' + playNumber) +
                ': No runner assigned to a ' +
                playEvent.playType.replace(/_/g, ' ')
              return false
            }
          } else if (
            playEvent.playType == 'error' ||
            playEvent.playType == 'error_baserunning'
          ) {
            var hasError = false
            if (playEvent.primaryFielderCredits) {
              for (var k = 0; k < playEvent.primaryFielderCredits.length; k++) {
                var fielder = playEvent.primaryFielderCredits[k]
                if (
                  fielder.credit == 'error' ||
                  fielder.credit == 'throwing_error' ||
                  fielder.credit == 'dropped_throw' ||
                  fielder.credit == 'interference'
                ) {
                  var hasError = true
                  break
                }
              }
            }
            if (!hasError) {
              for (var base in playEvent.runnerMovements) {
                var runner = playEvent.runnerMovements[base]
                if (runner.movements) {
                  for (var k = 0; k < runner.movements.length; k++) {
                    var movement = runner.movements[k]
                    if (movement.fielderCredits) {
                      for (var l = 0; l < movement.fielderCredits.length; l++) {
                        var fielder = movement.fielderCredits[l]
                        if (
                          fielder.credit == 'error' ||
                          fielder.credit == 'throwing_error' ||
                          fielder.credit == 'dropped_throw' ||
                          fielder.credit == 'interference'
                        ) {
                          hasError = true
                          break
                        }
                      }
                    }
                  }
                }
              }
            }

            if (!hasError) {
              this.playStringError =
                'At Bat ' +
                (i + 1) +
                (play.type == 'result'
                  ? ''
                  : ', Baserunning event ' + playNumber) +
                ': No fielder assigned with an error'
              return false
            }
          }

          var changedBases = []
          for (var base in playEvent.runnerMovements) {
            var runner = playEvent.runnerMovements[base]
            if (runner.hasChange) {
              changedBases.push(base)
            }
          }

          if (changedBases.length > 0) {
            this.playStringError =
              'At Bat ' +
              (i + 1) +
              (play.type == 'result'
                ? ''
                : ', Baserunning event ' + playNumber) +
              ': The runners at the following bases - ' +
              changedBases.join(', ') +
              " - were automatically moved due to a change in a previous play. Please click each runner and press 'Confirm' to confirm their updated position."
            return false
          }
        }
      }
    }
    return true
  }

  buildPlayByPlay = function (stringerData) {
    this.executePlayStrings(stringerData, false)
  }

  initialize = function (stringerData, rosters) {
    this.stringerData = stringerData
    this.awayTeamId = stringerData?.gameInfo?.awayTeamId
    this.homeTeamId = stringerData?.gameInfo?.homeTeamId
    this.atBats = stringerData?.atBats
    this.positionMap = {}
    this.runnerBaseMap = {}
    this.umpirePositionMap = {}
    this.playerNameMap = {}
    this.playerFullNameMap = {}
    this.playerLastNameMap = {}

    const rostersMap = new Map()
    if (Object.keys(rosters).length) {
      for (let category in rosters) {
        rosters[category].forEach((entry) => {
          rostersMap.set(entry.id, entry)
        })
      }
    }

    for (var i = 0; i < stringerData?.awayRoster?.length; i++) {
      this.playerNameMap[parseInt(stringerData.awayRoster[i].id)] =
        rostersMap.size
          ? rostersMap.get(stringerData.awayRoster[i].id)
              ?.number_name_last_first
          : stringerData.awayRoster[i].name
      this.playerFullNameMap[parseInt(stringerData.awayRoster[i].id)] =
        stringerData.awayRoster[i].nameFirstLast
      this.playerLastNameMap[parseInt(stringerData.awayRoster[i].id)] =
        stringerData.awayRoster[i].lastName
    }
    for (var i = 0; i < stringerData?.homeRoster?.length; i++) {
      this.playerNameMap[parseInt(stringerData.homeRoster[i].id)] =
        rostersMap.size
          ? rostersMap.get(stringerData.homeRoster[i].id)
              ?.number_name_last_first
          : stringerData.homeRoster[i].name
      this.playerFullNameMap[parseInt(stringerData.homeRoster[i].id)] =
        stringerData.homeRoster[i].nameFirstLast
      this.playerLastNameMap[parseInt(stringerData.homeRoster[i].id)] =
        stringerData.homeRoster[i].lastName
    }
    for (var i = 0; i < stringerData?.awayCoaches?.length; i++) {
      this.playerNameMap[parseInt(stringerData.awayCoaches[i].id)] =
        rostersMap.size
          ? rostersMap.get(stringerData.awayCoaches[i].id)
              ?.number_name_last_first
          : stringerData.awayCoaches[i].name
      this.playerFullNameMap[parseInt(stringerData.awayCoaches[i].id)] =
        stringerData.awayCoaches[i].fullName
      this.playerLastNameMap[parseInt(stringerData.awayCoaches[i].id)] =
        stringerData.awayCoaches[i].lastName
    }
    for (var i = 0; i < stringerData?.homeCoaches?.length; i++) {
      this.playerNameMap[parseInt(stringerData.homeCoaches[i].id)] =
        rostersMap.size
          ? rostersMap.get(stringerData.homeCoaches[i].id)
              ?.number_name_last_first
          : stringerData.homeCoaches[i].name
      this.playerFullNameMap[parseInt(stringerData.homeCoaches[i].id)] =
        stringerData.homeCoaches[i].fullName
      this.playerLastNameMap[parseInt(stringerData.homeCoaches[i].id)] =
        stringerData.homeCoaches[i].lastName
    }
    for (var i = 0; i < stringerData?.umpire?.length; i++) {
      this.playerNameMap[parseInt(stringerData.umpire[i].id)] = rostersMap.size
        ? rostersMap.get(stringerData.umpire[i].id)?.number_name_last_first
        : stringerData.umpire[i].name
      this.playerFullNameMap[parseInt(stringerData.umpire[i].id)] =
        stringerData.umpire[i].fullName || stringerData.umpire[i].name
      this.playerLastNameMap[parseInt(stringerData.umpire[i].id)] =
        stringerData.umpire[i].lastName || stringerData.umpire[i].name
    }
    for (var i = 0; i < stringerData?.scorers?.length; i++) {
      this.playerNameMap[parseInt(stringerData.scorers[i].id)] = rostersMap.size
        ? rostersMap.get(stringerData.scorers[i].id)?.number_name_last_first
        : stringerData.scorers[i].name
      this.playerFullNameMap[parseInt(stringerData.scorers[i].id)] =
        stringerData.scorers[i].fullName || stringerData.scorers[i].name
      this.playerLastNameMap[parseInt(stringerData.scorers[i].id)] =
        stringerData.scorers[i].lastName || stringerData.scorers[i].name
    }
    for (var i = 0; i < stringerData?.stringers?.length; i++) {
      this.playerNameMap[parseInt(stringerData.stringers[i].id)] =
        rostersMap.size
          ? rostersMap.get(stringerData.stringers[i].id)?.number_name_last_first
          : stringerData.stringers[i].name
      this.playerFullNameMap[parseInt(stringerData.stringers[i].id)] =
        stringerData.stringers[i].fullName || stringerData.stringers[i].name
      this.playerLastNameMap[parseInt(stringerData.stringers[i].id)] =
        stringerData.stringers[i].lastName || stringerData.stringers[i].name
    }

    for (var i = 0; i < stringerData?.positions?.length; i++) {
      this.positionMap[stringerData.positions[i].value + ''] =
        stringerData.positions[i].label
    }

    for (var i = 0; i < this.getRunnerBases()?.length; i++) {
      this.runnerBaseMap[this.getRunnerBases()[i].value + ''] =
        this.getRunnerBases()[i].label
    }

    for (var i = 0; i < this.getUmpirePositions()?.length; i++) {
      this.umpirePositionMap[this.getUmpirePositions()[i].value + ''] =
        this.getUmpirePositions()[i].label
    }

    if (stringerData.isFinal) {
      //this.buildPlayByPlay(stringerData);
    }
  }

  getAtBats = function () {
    return this.atBats
  }

  getPlayerName(pid) {
    if (this.playerNameMap[parseInt(pid)]) {
      var name = this.playerNameMap[parseInt(pid)]
      return this.formatName(name, pid)
    }
    return pid
  }

  formatName = function (name, id) {
    if (this.store.game.page != 'events' && this.showPlayerIds) {
      return name + ' (' + id + ')'
    }
    return name
  }

  getPlayerNameShort(pid) {
    if (this.playerNameMap[parseInt(pid)]) {
      var name = this.playerNameMap[parseInt(pid)] || ''
      var split = name.split(' ')
      var useName = ''
      if (split.length >= 3) {
        useName = split[0] + ' ' + split[1]
      } else {
        useName = split[0]
      }
      return this.formatName(useName, pid)
    }
    return pid
  }

  getPlayerFullName(pid) {
    if (this.playerFullNameMap[parseInt(pid)]) {
      var name = this.playerFullNameMap[parseInt(pid)]
      return this.formatName(name, pid)
    }
    return pid
  }

  getPlayerFullNameNoFormat(pid) {
    if (this.playerFullNameMap[parseInt(pid)]) {
      var name = this.playerFullNameMap[parseInt(pid)]
      return name
    }
    return null
  }

  getRunnerBase(pos) {
    if (this.runnerBaseMap[pos]) {
      return this.runnerBaseMap[pos]
    }
    return pos
  }

  getUmpirePosition(pos) {
    if (this.umpirePositionMap[pos]) {
      return this.umpirePositionMap[pos]
    }
    return pos
  }

  getPosition(pos) {
    if (this.positionMap[pos]) {
      return this.positionMap[pos]
    }
    return pos
  }

  numberWithOrdinal(number) {
    var label,
      str = number + '',
      lastDigit = str.substr(-2)

    if (lastDigit === '11' || lastDigit === '12' || lastDigit === '13') {
      label = 'th'
    } else {
      switch (~~lastDigit.substr(-1)) {
        case 1:
          label = 'st'
          break
        case 2:
          label = 'nd'
          break
        case 3:
          label = 'rd'
          break
        default:
          label = 'th'
      }
    }
    return number + label
  }

  addReviewPlayString(play) {
    var reviewStr = ''
    if (play.reviews.length > 0) {
      reviewStr += '['
      for (var i = 0; i < play.reviews.length; i++) {
        if (i > 0) {
          reviewStr += ']['
        }
        var reviewPlayString = this.getReviewPlayString(play.reviews[i])
        reviewStr += reviewPlayString
      }
      reviewStr += ']'
    }
    return reviewStr
  }

  getReviewPlayString(review) {
    var reviewStr = review.reviewStatus + '~'
    var isTeamReview = false
    switch (review.reviewedBy) {
      case 'home_team':
        reviewStr += this.homeTeamId
        isTeamReview = true
        break

      case 'away_team':
        reviewStr += this.awayTeamId
        isTeamReview = true
        break
    }
    if (review.inProgress) {
      reviewStr = 'REV~' + reviewStr
      if (!isTeamReview) {
        reviewStr = reviewStr.substring(0, reviewStr.length - 1)
      }
      return reviewStr
    }

    if (isTeamReview) {
      reviewStr += '~'
    }
    if (review.overturned) {
      if (review.type == 'pitch') {
        reviewStr += review.pitchCode
      } else {
        reviewStr += review.eventCode
      }
    } else {
      reviewStr += 'STD'
    }
    return reviewStr
  }

  generatePlayString(opts, atBat) {
    opts = opts || {}
    var playNum =
      opts.playNum || opts.playNum == 0 ? opts.playNum : atBat.plays.length - 1

    var pitchSequenceStr = ''
    var play = null
    var hitInPlaySeen = false
    var strikes = 0
    var balls = 0
    for (var i = 0; i <= playNum; i++) {
      play = atBat.plays[i]

      if (play.type == 'pitch') {
        if (this.getPitchType(play.pitchCode) == 'B') {
          balls++
        }
        if (this.getPitchType(play.pitchCode) == 'S') {
          if ('FR'.indexOf(play.pitchCode) == -1 || strikes < 2) {
            strikes++
          }
        }
        if (play.pitchType == 'X') {
          hitInPlaySeen = true
        }
        var pitchHasReview = false
        if (play.reviews.length == 0 && this.isActualPitch(play.pitchCode)) {
          for (var j = i + 1; j < atBat.plays.length; j++) {
            var innerPlay = atBat.plays[j]
            if (
              innerPlay.type == 'pitch' &&
              this.isActualPitch(innerPlay.pitchCode)
            ) {
              break
            }
            if (
              (innerPlay.type == 'play' || innerPlay.type == 'result') &&
              !this.isBlank(innerPlay.eventCode) &&
              innerPlay.reviews.length > 0
            ) {
              pitchHasReview = true
              break
            }
          }
        }
        if (play.reviews.length > 0 || pitchHasReview) {
          pitchSequenceStr += '^'
        }
        if (play.runnersGoing) {
          pitchSequenceStr += '>'
        }
        pitchSequenceStr += play.pitchCode
        if (opts.coords && play.pitchCode != '.') {
          if (play.coords) {
            pitchSequenceStr += '#' + play.coords + '#'
          } else {
            pitchSequenceStr += '#1,1#'
          }
          if (play.timecode) {
            pitchSequenceStr += '{' + play.timecode + '}'
          } else {
            pitchSequenceStr += '{' + new Date().getTime() + '}'
          }
        }
        if (opts.coords || opts.display) {
          pitchSequenceStr += this.addReviewPlayString(play)
        }
      }
    }
    var useStrikes = strikes
    if (hitInPlaySeen) {
      useStrikes--
    }
    if (useStrikes < 0) {
      useStrikes = 0
    }

    var count = balls + '' + useStrikes

    var playStr =
      'play,' +
      atBat.inning +
      ',' +
      (atBat.topInningSw == 'Y' ? 0 : 1) +
      ',' +
      play.batter +
      ',' +
      count +
      ',.' +
      pitchSequenceStr +
      ','
    if (!this.isBlank(play.eventCode)) {
      playStr += play.eventCode
      if (opts.coords || opts.display) {
        playStr += this.addReviewPlayString(play)
      }

      if (opts.coords) {
        if (play.coords && play.eventCode != 'NP') {
          playStr += '#' + play.coords + '#'
        } else if (play.eventCode != 'NP') {
          playStr += '#1,1#'
        }
        if (play.timecode && play.eventCode != 'NP') {
          playStr += '{' + play.timecode + '}'
        } else if (play.eventCode != 'NP') {
          playStr += '{' + new Date().getTime() + '}'
        }
      }
    }
    return playStr
  }

  buildPlayStrings(atBatIn) {
    var playStrings = []
    var lastPitchSeen = 0
    var resultSeen = false
    var hasBatter = false

    var atBat = merge({}, atBatIn)

    if (!this.stringerData.isFinal) {
      var usePlays = []
      for (var i = 0; i < atBat.plays.length; i++) {
        var play = atBat.plays[i]
        if (
          play.isInsert &&
          (play.type == 'eject' || play.type == 'sub' || play.type == 'play')
        ) {
          usePlays.push({
            playIdx: usePlays.length,
            type: 'play',
            eventCode: 'NP',
            reviews: [],
          })

          usePlays.push({
            playIdx: usePlays.length,
            type: 'pitch',
            pitchCode: '.',
            reviews: [],
          })
        }
        play.playIdx = usePlays.length
        usePlays.push(play)
      }
      atBat.plays = usePlays
    }
    var batter
    for (var i = 0; i < atBat.plays.length; i++) {
      var play = atBat.plays[i]
      if (play.type == 'batter') {
        play.playString = play.playString || play.playString
        batter = play.playString.split(',')[1]
        break
      }
    }
    for (var i = 0; i < atBat.plays.length; i++) {
      var play = atBat.plays[i]
      play.batter = batter
    }
    var batter
    for (var i = 0; i < atBat.plays.length; i++) {
      var play = atBat.plays[i]

      // if (play.batter){
      // 	batter = play.batter;
      // } else {
      // 	play.batter = batter;
      // }

      if (!play.timecode) {
        for (var j = i + 1; j < atBat.plays.length; j++) {
          if (atBat.plays[j].timecode) {
            play.timecode = parseInt(atBat.plays[j].timecode) - 1
            break
          }
        }
      }

      if (play.type == 'pitch') {
        if (!this.isNonPitch(play.pitchCode)) {
          lastPitchSeen = i
        }
      } else if (play.type == 'play' || play.type == 'result') {
        if (play.type == 'result') {
          resultSeen = true
        }

        var psObj = {
          playObj: atBatIn.plays[i],
          playIdx: i,
          playString: this.generatePlayString(
            {
              playNum: i,
            },
            atBat
          ),
          playStringCoords: this.generatePlayString(
            {
              playNum: i,
              coords: true,
            },
            atBat
          ),
          playStringDisplay: this.generatePlayString(
            {
              playNum: i,
              display: true,
            },
            atBat
          ),
          playByPlay: play.displayStr,
        }
        playStrings.push(psObj)
      } else {
        if (play.type == 'batter') {
          hasBatter = true
        }

        if (!play.playString) {
          if (play.playString) {
            play.playString = play.playString
          } else if (play.type == 'sub') {
            play.playString =
              'sub,' +
              play.subPlayer +
              ',"' +
              play.subPlayerName +
              '",' +
              play.subTeam +
              ',' +
              play.batPos +
              ',' +
              play.defPos +
              ',1'
          }
        }

        playStrings.push({
          playObj: atBatIn.plays[i],
          playIdx: i,
          playString: play.playString,
          playStringCoords:
            play.playString + (play.timecode ? '{' + play.timecode + '}' : ''),
          playStringDisplay: play.playStringDisplay,
          playByPlay: play.displayStr,
        })
      }
    }

    if (!resultSeen && hasBatter) {
      playStrings.push({
        playIdx: lastPitchSeen,
        playString: this.generatePlayString(
          {
            playNum: lastPitchSeen,
          },
          atBat
        ),
        playStringCoords: this.generatePlayString(
          {
            playNum: lastPitchSeen,
            coords: true,
          },
          atBat
        ),
        playStringDisplay: this.generatePlayString(
          {
            playNum: lastPitchSeen,
            display: true,
          },
          atBat
        ),
      })
    }

    return playStrings
  }

  isPickoffAttempt(pitchCode) {
    switch (pitchCode) {
      case '1':
      case '2':
      case '3':
      case '+1':
      case '+2':
      case '+3':
        return true
      default:
        return false
    }
  }

  isNonPitch(pitchCode) {
    switch (pitchCode) {
      case '.':
      case 'N':
        return true
      default:
        return false
    }
  }

  isActualPitch(pitchCode) {
    if (this.isPickoffAttempt(pitchCode)) {
      return false
    }

    if (this.isNonPitch(pitchCode)) {
      return false
    }
    return true
  }

  isBlank(str) {
    if (!str || str == '' || str == undefined || str == null) {
      return true
    } else {
      return false
    }
  }

  getPitchType(pitchCode) {
    switch (pitchCode) {
      case 'C':
      case 'S':
      case 'W':
      case 'T':
      case 'L':
      case 'M':
      case 'O':
      case 'Q':
      case 'A':
      case 'K':
      case 'F':
      case 'R':
        return 'S'
      case 'B':
      case '*B':
      case 'I':
      case 'P':
      case 'H':
      case 'V':
        return 'B'
      case 'X':
      case 'D':
      case 'E':
      case 'Y':
      case 'J':
      case 'Z':
        return 'X'
    }
  }

  updateAtBat(atBat) {
    var newPlayStrings = this.buildPlayStrings(atBat)
    // adjust all the other play string numbers
    if (newPlayStrings.length != atBat.playNumbers.length) {
      var delta = newPlayStrings.length - atBat.playNumbers.length
      for (var i = atBat.atBatIdx + 1; i < this.atBats.length; i++) {
        var otherAtBat = this.atBats[i]
        for (var j = 0; j < otherAtBat.playNumbers.length; j++) {
          otherAtBat.playNumbers[j] += delta
        }
      }
    }
    var startPlayNumber = atBat.playNumbers[0]
    for (var i = 0; i < newPlayStrings.length; i++) {
      newPlayStrings[i].atBatNumber = atBat.atBatIdx + 1
      newPlayStrings[i].playNumber = startPlayNumber + i
      if (i < atBat.playNumbers.length) {
        newPlayStrings[i].oldPlayNumber = startPlayNumber + i
      }
    }
    return newPlayStrings
  }

  getPlayerNameShortNoNumber(pid) {
    if (this.playerLastNameMap[parseInt(pid)]) {
      return this.playerLastNameMap[parseInt(pid)] || ''
    }
    return pid
  }

  getPlayerNameBoxscore(pid) {
    if (this.playerNameMap[parseInt(pid)]) {
      var name = this.playerNameMap[parseInt(pid)] || ''
      var split = name.split(' ')
      var useName = this.getPlayerNameShortNoNumber(pid)
      if (split[split.length - 1] && split[split.length - 1][0]) {
        useName += ', ' + split[split.length - 1][0]
      }
      return this.formatName(useName, pid)
    }
    return pid
  }
}
