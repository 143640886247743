import cn from 'classnames'
import { observer } from 'mobx-react'
import { useStore } from '../../../hooks'
import { useState } from 'react'
import moment from 'moment/moment'
import { PlayEventsToggle } from './PlayEventsToggle'
import { AddPlayEventButton } from './AddPlayEventButton'
import { EditPlayEventButton } from './EditPlayEventButton'

export const TrackedEventsTable = observer(() => {
  const store = useStore()
  const { hasPlayEventEditingPermission } = store.auth

  const { data = {}, rawPlayEvents } = store.play
  const { trackedEvents = [], metricErrors = [], startTime } = data

  const [showRawEvents, setShowRawEvents] = useState(false)
  const visibleEvents = showRawEvents ? rawPlayEvents : trackedEvents
  const beginOfPlayEventTimeStamp = trackedEvents.find((event) => {
    return event.playEvent === 'BEGIN_OF_PLAY'
  })?.timeStamp
  const startTimeMs = startTime
    ? new Date(startTime).getTime()
    : new Date(beginOfPlayEventTimeStamp).getTime()

  const mergedEvents = visibleEvents
    .slice()
    .concat(
      metricErrors
        .slice()
        .filter((metricError) => !!metricError.timestamp)
        .map((metricError) => {
          return {
            ...metricError,
            timeStamp: metricError.timestamp.replace('+00:00', ''),
            playEvent: metricError.notes,
            actionable: true,
          }
        })
    )
    .slice()
    .map((item) => {
      let { playEvent, type, operationType, playTime, timeStamp } = item
      const playEventId = item.playEventId ? item.playEventId : item.playEventID //This is because the raw and tracked event lists have different keys
      if (!timeStamp) {
        if (!playTime) {
          timeStamp = startTime
        } else {
          timeStamp = moment(playTime / 1000)
          let offset = timeStamp.utcOffset()
          timeStamp = timeStamp.subtract(offset, 'minutes')
        }
      }

      let ms = new Date(timeStamp).getTime()
      let displayTime = ((ms - startTimeMs) / 1000).toFixed(2)

      // On rare occasions, startTime or timeStamp is not being set on the game endpoint on the API, which results in displayTime of NaN
      // This isn't particularly elegant to see on the UI, and we can't really find the difference amongst the two without both,
      // so instead lets display it as "Not Available"
      if (displayTime === 'NaN') {
        displayTime = 'N/A'
      } else if (displayTime >= 3600) {
        // fixes: https://baseball.atlassian.net/browse/CORE-30
        displayTime = (displayTime - 3600).toFixed(2)
      }

      if (!playEvent) {
        playEvent = [operationType, type].filter((t) => !!t).join(' ')
      }

      return Object.assign({}, item, {
        timeStamp: timeStamp,
        displayTime,
        playEvent,
        playEventId,
      })
    })
    .sort((a, b) => {
      let aTime = new Date(a.timeStamp).getTime()
      let bTime = new Date(b.timeStamp).getTime()

      if (aTime < bTime) {
        return -1
      } else {
        return 1
      }
    })

  return (
    <div>
      {hasPlayEventEditingPermission ? (
        <div className='mb-3'>
          <PlayEventsToggle
            toggle={setShowRawEvents}
            showRawEvents={showRawEvents}
          />
        </div>
      ) : null}
      <table className='table is-small is-fullwidth is-bordered mb-5'>
        <thead>
          <tr>
            <th>#</th>
            <th>Time</th>
            <th>Event</th>
            <th>Pos</th>
            {hasPlayEventEditingPermission && <th></th>}
          </tr>
        </thead>
        <tbody>
          {mergedEvents.map((event, i) => {
            let { playEvent, positionId, displayTime, actionable } = event

            const displayEvent = (playEvent || '')
              .toLowerCase()
              .replace(/_/g, ' ')
              .replace(/ball was /g, '')
              .replace(/ of play/g, '')

            return (
              <tr
                key={i}
                className={cn({
                  'bg-info': displayEvent === 'fielded',
                  'bg-danger': actionable,
                  'bg-success':
                    displayEvent === 'pitched' || displayEvent === 'hit',
                  'bg-grey': displayEvent === 'end',
                })}
              >
                <td style={{ textAlign: 'center' }}>{i + 1}</td>
                <td style={{ textAlign: 'center' }}>
                  {displayTime || <span>&nbsp;</span>}
                </td>
                <td className='is-capitalized'>
                  {displayEvent || <span>&nbsp;</span>}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {positionId >= 0 ? positionId : <span>&nbsp;</span>}
                </td>
                {hasPlayEventEditingPermission && (
                  <td style={{ textAlign: 'center' }}>
                    <EditPlayEventButton
                      {...event}
                      firstEvent={mergedEvents[0]}
                      playId={store.play.playId}
                    />
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>
      {hasPlayEventEditingPermission ? (
        <AddPlayEventButton
          firstEvent={mergedEvents[0]}
          playId={store.play.playId}
        />
      ) : null}
    </div>
  )
})
