import axios from 'axios'
import { get } from 'lodash'
import { action, computed, observable } from 'mobx'
import moment from 'moment/moment'

const CancelToken = axios.CancelToken

let source = CancelToken.source()
const fetch = (options) =>
  axios(
    Object.assign({}, options, {
      cancelToken: source.token,
    })
  ).then((response) => response.data)

export default class UniformStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  POLLING_INTERVAL = 15000

  @action load() {
    if (this.isLoading || this.page !== 'uniforms') {
      return
    }

    this.isLoading = true
    this.setInt()
    this.update()
      .catch(
        action((error) => {
          console.error(error)
        })
      )
      .finally(
        action(() => {
          this.isLoading = false
        })
      )
  }

  setInt() {
    clearInterval(this.int)
    this.int = null

    this.int = setInterval(() => {
      this.update()
    }, this.POLLING_INTERVAL)
  }

  clearInt() {
    clearInterval(this.int)
    this.int = null
  }

  _updating = false

  update(forceUpdate) {
    if (!this.shouldFetch) {
      this.clearInt()
      return Promise.resolve()
    }

    if (this._updating) {
      console.info('Slow network detected. Skipping polling cycle.')
      return Promise.resolve()
    }

    this._updating = true
    return this.fetch()
      .then(
        action((data) => {
          this.merge(data)
          this._updating = false
        })
      )
      .catch(
        action((error) => {
          this._updating = false
          //throw error
        })
      )
  }

  fetch() {
    const id = this.store.gamePk ? this.store.gamePk : this.store.eventId
    if (this.shouldFetch) {
      const url = '/api/uniform/' + id
      return fetch({
        url: url,
      })
    }
  }

  @action merge(data) {
    let { game, gameUniforms, teamUniforms } = data
    if (game) {
      this.game = game
    }
    if (gameUniforms) {
      this.gameUniforms = gameUniforms
    }

    if (teamUniforms) {
      this.teamUniforms = teamUniforms.map((uniform) => {
        return {
          value: uniform.uniformAssetId,
          label: uniform.uniformAssetText,
          teamId: uniform.teamId,
          assetType: uniform.uniformAssetTypeId,
        }
      })
    }
  }

  @observable gameUniforms = []
  @observable teamUniforms = []
  @observable game = {}
  @observable isLoading = false

  @computed get awayTeamShortName() {
    return get(this.game, 'teams.away.team.teamName', '') || 'Away'
  }

  @computed get homeTeamShortName() {
    return get(this.game, 'teams.home.team.teamName', '') || 'Home'
  }

  @computed get homeTeamUniformOptions() {
    const homeId = get(this.game, 'teams.home.team.id', '')
    if (this.teamUniforms) {
      return Object.groupBy(
        this.teamUniforms.filter((uniform) => uniform.teamId === homeId),
        ({ assetType }) => assetType
      )
    }
  }

  @computed get awayTeamUniformOptions() {
    const awayId = get(this.game, 'teams.away.team.id', '')
    if (this.teamUniforms) {
      return Object.groupBy(
        this.teamUniforms.filter((uniform) => uniform.teamId === awayId),
        ({ assetType }) => assetType
      )
    }
  }

  @computed get awayUniformsForGame() {
    const awayId = get(this.game, 'teams.away.team.id', '')
    if (this.gameUniforms) {
      const awayUniforms = this.gameUniforms.filter(
        (uniform) => uniform.teamId === awayId
      )
      return awayUniforms.reduce(function (map, uniform) {
        map[uniform.uniformAssetTypeId] = {
          value: uniform.uniformAssetId,
          label: uniform.uniformAssetText,
        }
        return map
      }, {})
    }
    return {}
  }

  @computed get homeUniformsForGame() {
    const homeId = get(this.game, 'teams.home.team.id', '')
    if (this.gameUniforms) {
      const homeUniforms = this.gameUniforms.filter(
        (uniform) => uniform.teamId === homeId
      )
      return homeUniforms.reduce(function (map, uniform) {
        map[uniform.uniformAssetTypeId] = {
          value: uniform.uniformAssetId,
          label: uniform.uniformAssetText,
        }
        return map
      }, {})
    }
    return {}
  }

  @action refreshGameUniforms() {
    return fetch({
      url: `/api/uniform/${this.store.gamePk}/uniforms`,
    })
      .then((gameUniforms) => {
        this.gameUniforms.replace(gameUniforms)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  saveUniforms(uniforms, isHomeTeam) {
    return this.postUniforms(uniforms, isHomeTeam).then(() =>
      this.store.uniforms.refreshGameUniforms()
    )
  }

  @computed get shouldFetch() {
    return (
      this.store.isAuthenticated &&
      this.store.isUniformPage &&
      (this.store.gamePk || this.store.eventId)
    )
  }

  @computed get scheduleStatusText() {
    const codedGameState = get(this.game, 'status.codedGameState')
    const detailedState = get(this.game, 'status.detailedState')
    if (!codedGameState) {
      return ''
    }
    return `${codedGameState} - ${detailedState}`
  }

  @computed get gameMatchupText() {
    const separator = ' - '
    const abstractGameCode = get(this.game, 'status.abstractGameCode')
    const officialDate = get(this.game, 'officialDate')
    const dateFormat = 'YYYY-MM-DD'
    const dateText = officialDate ? moment(officialDate).format(dateFormat) : ''

    const awayName = get(this.game, 'teams.away.team.abbreviation', '')
    const homeName = get(this.game, 'teams.home.team.abbreviation', '')

    const teamText = awayName && homeName ? `${awayName}  @ ${homeName}` : ''

    if (['P', 'F'].includes(abstractGameCode)) {
      return [this.scheduleStatusText, teamText, dateText]
        .filter(Boolean)
        .join(separator)
    }

    return [this.scheduleStatusText, teamText, dateText]
      .filter(Boolean)
      .join(separator)
  }

  @action postUniforms(uniforms, isHomeTeam) {
    const teamId = isHomeTeam
      ? get(this.game, 'teams.home.team.id', '')
      : get(this.game, 'teams.away.team.id', '')
    return fetch({
      method: 'POST',
      url: `/api/uniform/${this.store.gamePk}/${teamId}`,
      data: this.convertUniformPayload(uniforms, teamId),
    })
  }

  convertUniformPayload(uniforms, teamId) {
    const payload = []
    for (const uniformLocation of Object.keys(uniforms)) {
      if (uniforms[uniformLocation]) {
        payload.push({
          teamId: teamId,
          uniformAssetId: uniforms[uniformLocation].value,
          uniformAssetTypeId: uniformLocation,
          uniformLocationId: -1,
        })
      }
    }
    return payload
  }

  @computed get page() {
    let re = /\/games\/\d+\/([a-zA-Z]+)/
    let match = this.store.pathname.match(re)

    if (match && match.length > 1) {
      return match[1]
    }
    return null
  }

  @action reset() {
    this.gameUniforms = []
    this.teamUniforms = []
    this.game = {}
  }

  initialize() {
    this.load()
  }
}
