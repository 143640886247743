import { StatcastPitch } from '@baseball-data/react-kit'
import { useStore } from 'hooks'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useMemo } from 'react'

export const GamedayCard = (props) => {
  const { title = 'Preview' } = props

  return (
    <div className='card mb-4'>
      {title ? (
        <header
          className='card-header'
          style={{ backgroundColor: 'whitesmoke' }}
        >
          <p className='card-header-title has-text-centered'>{title}</p>
        </header>
      ) : null}
      <div className='card-content'>
        <GamedayCardContent />
      </div>
    </div>
  )
}


export const GamedayCardContent = inject('store')(
  observer((props) => {
    let { pfxData = {} } = props
	
	const pfxOptions = useMemo(
		() => toJS(pfxData),
		[pfxData?.pitches?.length]
	 )
	 
     return <StatcastPitch {...pfxOptions} />
	 
  })
)


export const GamedayEventCardContent = observer(() => {
  const store = useStore()
  const { pfxData = {}, analytics = {} } = store.play

  pfxData.pitchSegment = analytics?.ballSegments?.pitchSegment
  const pitches = [pfxData]
  const batSide = pfxData?.batSide
  const dayNight = pfxData?.dayNight
  const homeAway = pfxData?.homeAway
  const venue = pfxData?.venue

  return (
    <StatcastPitch
      pitches={pitches}
      batSide={batSide}
      dayNight={dayNight}
      homeAway={homeAway}
      venue={venue}
    />
  )
})