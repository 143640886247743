import axios from 'axios'
import { PlayTab } from 'components/play/Play'
import { action, computed, observable, reaction } from 'mobx'
import { HttpMethod } from 'shared/constants'
import uuid from 'uuid'
import { NotificationTypes, TRACKMAN_POSITIONS } from '../constants'

const CancelToken = axios.CancelToken
let source = CancelToken.source()

const fetch = (options) =>
  axios(
    Object.assign({}, options, {
      // cancelToken: source.token
    })
  ).then((response) => response.data)

export default class PlayStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  POLLING_INTERVAL = 15000

  @observable _requests = {}

  @computed get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  @observable data = {}
  rawPositionalData = {}
  tabsLoaded = {}
  @observable analytics = {}

  @computed get isLoaded() {
    return this.playId == this.loadedPlayId
  }

  @computed get atBatIdx() {
    const { atBats = [] } = this.store.game

    for (let i = 0; i < atBats.length; i++) {
      const atBat = atBats[i]
      const { plays = [] } = atBat

      for (let j = 0; j < plays.length; j++) {
        const play = plays[j]
        const { playId, atBatIdx } = play

        if (playId == this.playId) {
          return atBatIdx
        }
      }
    }

    return null
  }

  @computed get videoSrc() {
    if (this.store.isPlayPage) {
      return this.data.videoSrc || null
    }

    const guid = this.store.game.guids.find(
      (guid) => guid.playId == this.playId
    )

    if (guid) {
      return guid.videoSrc
    }

    return null
  }

  @computed get videos() {
    const guid = this.store.game.guids.find(
      (guid) => guid.playId == this.playId
    )

    if (guid) {
      return guid.videos
    }

    return []
  }

  @computed get blockedVideos() {
    const blockedVideosForPlay = this.store.game.blockedVideos.filter(
      (blockedVideo) => blockedVideo.playId == this.playId
    )
    return blockedVideosForPlay
  }

  @computed get pfxData() {
    const guid = this.store.game.guids.find(
      (guid) => guid.playId == this.playId
    )

    if (guid) {
      return guid.pfxData
    }

    return {}
  }

  @computed get diagramSrc() {
    const { diagramSrc = '' } = this.data

    return diagramSrc
  }

  /** @returns {Array<*>} */
  @computed get calibrationAssets() {
    return this.data?.calibrationAssets ?? []
  }

  @computed get _tab() {
    return this.store.hash.playTab
  }

  @computed get tab() {
    if (this._tab) {
      return this._tab
    }

    if (this.store.game.page === 'tracking') {
      return 'diagram'
    }

    return 'video'
  }

  @computed get playId() {
    const path = this.store.path

    const rgx = /\/games\/\d+\/plays\/([a-zA-Z0-9\-]*)\/?/
    const match = rgx.exec(path)

    if (match && match[1]) {
      return match[1]
    }

    const rgxEventsPlay = /\/nonGameEvents\/\d+\/plays\/([a-zA-Z0-9\-]*)\/?/
    const matchEventsPlay = rgxEventsPlay.exec(path)

    if (matchEventsPlay && matchEventsPlay[1]) {
      return matchEventsPlay[1]
    }

    const rgxEvents = /\/nonGameEvents\/(\d+)\/?/
    const matchEvents = rgxEvents.exec(path)

    if (matchEvents && matchEvents[1]) {
      return this.store.hash.playId
    }

    const atBat = this.store.game.atBats.find(
      (atBat) => atBat.atBatIdx == this.store.game.atBatIdx && atBat.plays
    )
    const plays = (atBat && atBat.plays) || []
    const play = plays.find((play) => play.playIdx == this.store.game.playIdx)

    return (play && play.playId) || null
  }

  @action setPlayId(playId) {
    this.store.hash.set('playId', playId)
  }

  @computed get gamePk() {
    const rgxGames = /\/games\/(\d+)\/?/
    const matchGames = rgxGames.exec(this.store.path)

    if (matchGames && matchGames[1]) {
      return parseInt(matchGames[1])
    }

    const rgxEvents = /\/nonGameEvents\/(\d+)\/?/
    const matchEvents = rgxEvents.exec(this.store.path)

    return matchEvents && matchEvents[1] ? parseInt(matchEvents[1]) : null
  }

  @action setTab(tab) {
    this.store.hash.set('playTab', tab)
  }

  @computed get playText() {
    if (!this.inning || !this.atBatNumber) {
      return ''
    }
    const inningState = this.isTopInning ? 'Top' : 'Bottom'

    let text = `${inningState} ${this.inning}, AB ${this.atBatNumber}, Pitch ${this.pitchNumber}`

    if (this.batterName && this.pitcherName) {
      text += `, P. ${this.pitcherName} to B. ${this.batterName}`
    }

    return text
  }

  @computed get backlog() {
    if (this.store.game.backlog.length) {
      return this.store.game.backlog.filter(
        (item) => item.playId === this.playId
      )
    }

    return this.data.backlog
  }

  @computed get inning() {
    return this.data.inning
  }

  @computed get isTopInning() {
    return this.data.isTopInning
  }

  @computed get atBatNumber() {
    return this.data.atBatNumber
  }

  @computed get pitchNumber() {
    return this.data.pitchNumber
  }

  @computed get venue() {
    return this.store.game.schedule.venue
  }

  @computed get batter() {
    return this.data.batter
  }

  @computed get batterName() {
    return this.batter.fullName || ''
  }

  @computed get pitcher() {
    return this.data.pitcher
  }

  @computed get pitcherName() {
    return this.pitcher.fullName || ''
  }

  @computed get runnersOn() {
    return this.data.runnersOn
  }

  @computed get untagged() {
    if (
      Object.keys(this.stringerPlay).length === 0 &&
      Object.keys(this.trackedPlay).length !== 0
    ) {
      return true
    }

    return false
  }

  @action merge(data) {
    const { positionalData: rawPositionalData, badPlayData, analytics } = data

    delete data.positionalData

    this.data = data
    this.rawPositionalData = rawPositionalData
    this.badPlayData = badPlayData
    this.analytics = analytics
    this.tabsLoaded[this.tab] = true
  }

  @computed get loadedPlayId() {
    const { playId } = this.data

    return playId || null
  }

  @observable badPlayData = []
  @observable rawPlayEvents = []

  @computed get positions() {
    const positions = TRACKMAN_POSITIONS.map((position) => {
      position.display = position.value + ' - ' + position.label
      return position
    })
    return positions
  }

  @action addBadPlayData(badPlayData) {
    const { playId, gamePk } = this
    const { description, positionId, metricOverrideTypeId } = badPlayData

    const requestId = uuid()
    this._requests[requestId] = true

    fetch({
      method: 'POST',
      url: '/api/plays/badPlayData',
      data: {
        gamePk,
        playId,
        description,
        positionId,
        metricOverrideTypeId,
      },
    })
      .then(() => {
        this.reload()
        this.store.notifications.trigger(
          NotificationTypes.ADD_BAD_PLAY_DATA_SUCCESS,
          2
        )
      })
      .catch((e) => {
        console.error(e)
        this.store.notifications.trigger(
          NotificationTypes.ADD_BAD_PLAY_DATA_ERROR,
          2
        )
      })
      .finally(
        action(() => {
          delete this._requests[requestId]
        })
      )
  }

  @action deleteBadPlayData(badPlayData) {
    if (window.confirm('Are you sure you want to delete this bad play data?')) {
      const { playId, gamePk } = this
      const { positionId, metricOverrideTypeId } = badPlayData

      const requestId = uuid()
      this._requests[requestId] = true

      fetch({
        method: 'DELETE',
        url: '/api/plays/badPlayData',
        data: {
          gamePk,
          playId,
          positionId,
          metricOverrideTypeId,
        },
      })
        .then(() => {
          this.reload()
          this.store.notifications.trigger(
            NotificationTypes.DELETE_BAD_PLAY_DATA_SUCCESS,
            2
          )
        })
        .catch((e) => {
          console.error(e)
          this.store.notifications.trigger(
            NotificationTypes.DELETE_BAD_PLAY_DATA_ERROR,
            2
          )
        })
        .finally(
          action(() => {
            delete this._requests[requestId]
          })
        )
    }
  }

  @action fetchRawPlayEvents() {
    const requestId = uuid()
    this._requests[requestId] = true

    return fetch({
      url: `/api/plays/rawPlayEvents`,
      params: {
        gamePk: this.gamePk,
        playId: this.playId,
      },
    })
      .then((data) => {
        this.rawPlayEvents = data.data || []
      })
      .catch((err) => console.error(err))
      .finally(
        action(() => {
          delete this._requests[requestId]
        })
      )
  }

  @action savePlayEvent(payload) {
    const requestId = uuid()
    this._requests[requestId] = true

    return fetch({
      method: HttpMethod.POST,
      url: `/api/plays/playEvent`,
      data: {
        gamePk: this.gamePk,
        payload: payload,
      },
    })
      .then(() => {
        this.reload()
        return {
          success: true,
        }
      })
      .catch((err) => {
        console.error(err)
        return { success: false, error: err }
      })
      .finally(
        action(() => {
          delete this._requests[requestId]
        })
      )
  }

  @action fetchBlockedVideos() {
    const requestId = uuid()
    this._requests[requestId] = true

    return fetch({
      url: `/api/plays/badVideo`,
      params: {
        gameId: this.gamePk,
        playId: this.playId,
      },
    })
      .then((data) => {
        this.blockedVideos = data.data || []
      })
      .catch((err) => console.error(err))
      .finally(
        action(() => {
          delete this._requests[requestId]
        })
      )
  }

  @action deletePlayEvent(playEventID, playEventType, timeStamp) {
    const { playId, gamePk } = this
    const gameTs =
      timeStamp ||
      this.store.play.data.trackedEvents.find((event) => {
        return event.playEventType === playEventType
      })?.timeStamp
    const requestId = uuid()
    this._requests[requestId] = true

    return fetch({
      method: HttpMethod.DELETE,
      url: '/api/plays/playEvent',
      data: {
        gamePk,
        playId,
        playEventID,
        timestamp: gameTs,
      },
    })
      .then(() => {
        this.store.notifications.setMessage('Successfully deleted Play Event')
        this.store.notifications.trigger(NotificationTypes.GENERIC_SUCCESS, 2)
      })
      .catch((e) => {
        console.error(e)
        this.store.notifications.setMessage('Error Deleting Play Event')
        this.store.notifications.trigger(NotificationTypes.GENERIC_ERROR, 2)
      })
      .finally(
        action(() => {
          delete this._requests[requestId]
          this.reload()
          return this.fetchRawPlayEvents()
        })
      )
  }

  @action fetch(showLoader) {
    const requestId = uuid()

    if (showLoader) {
      this._requests[requestId] = true
    }

    return fetch({
      url: `/api/plays`,
      params: {
        gamePk: this.gamePk,
        playId: this.playId,
        page: this.store.game.page,
        tab: this.tab,
      },
    })
      .then((data) => this.merge(data))
      .catch((err) => console.error(err))
      .finally(
        action(() => {
          delete this._requests[requestId]
        })
      )
  }

  @action reset() {
    this.data = {}
    this.rawPositionalData = {}
    this.analytics = {}
    this.tabsLoaded = {}
  }

  @action reload() {
    this.reset()
    source.cancel()
    source = CancelToken.source()
    this.fetch(true)
  }

  @computed get shouldFetch() {
    return (
      this.store.auth.isAuthenticated &&
      ((!!this.playId && !!this.store.gamePk) || !!this.store.hash.playId)
    )
  }

  initialize() {
    if (this.shouldFetch) {
      this.reload()
    }

    this.shouldFetchReaction = reaction(
      () => ({
        playId: this.playId,
        shouldFetch: this.shouldFetch,
        tab: this.tab,
      }),
      ({ shouldFetch, playId, tab, page }) => {
        if (!shouldFetch) {
          return this.reset()
        }

        if (playId === this.loadedPlayId && this.tabsLoaded[tab]) {
          return
        }

        if (
          [
            PlayTab.Diagram,
            PlayTab.Simulator,
            PlayTab.BadPlays,
            PlayTab.Calibration,
            PlayTab.OS, // need to make sure that we have the tracked events object for hit vs. error
          ].includes(tab) ||
          (this.store.path.indexOf('nonGameEvents') != -1 &&
            [PlayTab.Gameday].includes(tab))
        ) {
          this.reload()
        }
      }
    )
  }
}
