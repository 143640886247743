import io from 'socket.io-client'
import { action } from 'mobx'

export default class Socket {
  constructor(store) {
    this.store = store
    this.connect()
  }

  connect() {
    if (this.io) {
      this.disconnect()
    }

    this.io = io({
      transports: ['websocket'],
    })

    // on reconnection, reset the transports option, as the Websocket
    // connection may have failed (caused by proxy, firewall, browser, ...)
    // this.io.on('reconnect_attempt', () => {
    //     this.io.opts.transports = ['polling', 'websocket']
    // })

    this.io.on('confirmation', (data) => {
      this.store.updates.confirm(data)
    })

    this.io.on(
      'pitchData',
      action((data) => {
        let games = this.store.schedule.games

        for (var i = 0; i < games.length; i++) {
          let game = games[i]

          if (game.gamePk == data.gamePk) {
            // merge into game.lastPitch

            game.lastPitch = data
          }
        }
      })
    )
  }

  disconnect() {
    this.io.disconnect()
    this.io = null
  }
}
