import axios from 'axios'
import { action, computed, observable, reaction } from 'mobx'
import moment from 'moment'
import ActionStore from 'stores/ActionStore'
import AuditStore from 'stores/AuditStore'
import AuthStore from 'stores/AuthStore'
import BossStore from 'stores/BossStore'
import ConfigStore from 'stores/ConfigStore'
import EditStore from 'stores/EditStore'
import GameStore from 'stores/GameStore'
import NotificationStore from 'stores/NotificationStore'
import PitchModalStore from 'stores/PitchModalStore'
import PlayStore from 'stores/PlayStore'
import PostGameStore from 'stores/PostGameStore'
import PreGameStore from 'stores/PreGameStore'
import ScheduleStore from 'stores/ScheduleStore'
import SocketStore from 'stores/SocketStore'
import StatusStore from 'stores/StatusStore'
import UIStore from 'stores/UIStore'
import UpdateStore from 'stores/UpdateStore'
import DWareUtil from '../util/dware'
import HashStore from './HashStore'
import OperatorChangeReasonStore from './OperatorChangeReasonStore'
import UniformStore from './UniformStore'
import OSCardStore from './OSCardStore'

export default class AppStore {
  constructor(router) {
    this.router = router
    this.hash = new HashStore(this)
    this.dware = new DWareUtil(this)
    this.auth = new AuthStore(this)
    this.config = new ConfigStore(this)
    this.game = new GameStore(this)
    this.pregame = new PreGameStore(this)
    this.postgame = new PostGameStore(this)
    this.play = new PlayStore(this)
    this.audit = new AuditStore(this)
    this.edit = new EditStore(this)
    this.updates = new UpdateStore(this)
    this.notifications = new NotificationStore(this)
    this.schedule = new ScheduleStore(this)
    this.socket = new SocketStore(this)
    this.actions = new ActionStore(this)
    this.ui = new UIStore(this)
    this.status = new StatusStore(this)
    this.pitchModal = new PitchModalStore(this)
    this.boss = new BossStore(this)
    this.operatorChangeReason = new OperatorChangeReasonStore(this)
    this.uniforms = new UniformStore(this)
    this.osCard = new OSCardStore(this)

    this.initialize()
  }

  @computed get date() {
    return moment.utc().subtract(12, 'h')
  }

  /*
   *   Auth
   */

  @computed get isAuthenticated() {
    return this.auth.isAuthenticated
  }

  /*
   *   Routing
   */

  @computed get path() {
    return this.pathname
  }

  @computed get page() {
    return null
    let page = null

    let re = new RegExp(`(${Object.keys(this.schedule.tabs).join('|')})?`)
    if (re.test(this.pathname)) {
      page = 'games'
    }

    re = /^\/games\/\d+\/\w+/
    if (re.test(this.pathname)) {
      page = 'game'
    }

    re = /^\/games\/\d+\/\plays\/[A-Za-z0-9]*\/?/
    if (re.test(this.pathname)) {
      page = 'play'
    }

    return page
  }

  @computed get isSchedulePage() {
    let re = new RegExp(`^\/(${Object.keys(this.schedule.tabs).join('|')}|)?$`)
    return re.test(this.path)
  }

  @computed get isCacheBustPage() {
    return /^\/cache\/?/.test(this.path)
  }

  @computed get isAbsPage() {
    return /^\/abs\/?/.test(this.path)
  }

  @computed get isAmqPage() {
    return /^\/amq\/?/.test(this.path)
  }

  @computed get isGamePage() {
    return /^\/games\/?/.test(this.path)
  }

  @computed get isNonGameEventPage() {
    return /^\/nonGameEvents\/?/.test(this.path)
  }

  @computed get isUniformPage() {
    return /^\/games\/\d+\/uniforms?/.test(this.path)
  }

  @computed get isPlayPage() {
    return /^\/games\/\d+\/plays?/.test(this.path)
  }

  @computed get isBossCodePage() {
    return /^\/games\/\d+\/boss/.test(this.path)
  }

  @computed get title() {
    let title = 'NORAD'

    if (this.isGamePage) {
      let { gameInfo = {} } = this.game

      let { awayAbbrev: away, homeAbbrev: home, gameDate = '' } = gameInfo

      if (away && home) {
        title = `${away}@${home} ${gameDate}`
      }
    }

    return title
  }

  @computed get pathname() {
    return this.router.location ? this.router.location.pathname : '/'
  }

  @computed get url() {
    return this.router.location
      ? this.router.location.pathname + this.router.location.hash
      : '/'
  }

  // @computed get hashString() {
  //     let str = this.router.location && this.router.location.hash ? this.router.location.hash : ''

  //     return str.replace(/^#/, '')
  // }

  // @computed get hash() {
  //     return this.hashString
  //         .split('&')
  //         .filter(thing => thing)
  //         .reduce((map, keyVal) => {
  //             let [key, val] = keyVal.split('=')

  //             if (key && val) {
  //                 map[key] = val
  //             }

  //             return map
  //         }, {})
  // }

  // @action setHash(hash) {
  //     let str = Object.keys(hash)
  //         .map(key => `${key}=${hash[key]}`)
  //         .join('&')

  //     this.router.push({
  //         pathname: this.path,
  //         hash: str,
  //     })
  // }

  // @action setOrDeleteHashKey(key, val) {
  //     let hash = Object.assign({}, this.hash)

  //     if (val) {
  //         hash[key] = val
  //     } else {
  //         delete hash[key]
  //     }

  //     this.setHash(hash)
  // }

  @observable referrer = '/'
  @action setReferrer() {
    this.referrer = this.url
  }

  @computed get gamePk() {
    let rgx = /\/games\/(\d+)\/?/
    let match = rgx.exec(this.path)

    return match && match[1] ? parseInt(match[1]) : null
  }

  @computed get eventId() {
    let rgx = /\/nonGameEvents\/(\d+)\/?/
    let match = rgx.exec(this.path)

    return match && match[1] ? parseInt(match[1]) : null
  }

  @computed get host() {
    let rgx = /\/amq\/(.+)\/?/
    let match = rgx.exec(this.path)

    return match && match[1] ? match[1] : null
  }

  @computed get playId() {
    let rgx = /\/games\/\d+\/plays\/([a-zA-Z0-9\-]*)\/?/
    let match = rgx.exec(this.path)

    return match && match[1] ? match[1] : null
  }

  @observable env = ''
  @observable app = ''
  @observable region = ''
  @observable version = ''
  @observable minifeedBaseURL = ''
  @observable researchToolBaseURL = ''
  @observable showTracking = false
  @observable defaultGamePage = 'plays'
  @observable checkBenderHealth = true
  @observable checkPlayBuilderHealth = true

  @action getEnv() {
    return axios
      .get('/api/env')
      .then((r) => r.data)
      .then(
        action((data) => {
          console.info(JSON.stringify(data, null, 2))
          this.app = data.app
          this.env = data.env
          this.region = data.region
          this.version = data.version
          this.minifeedBaseURL = data.minifeedBaseURL
          this.researchToolBaseURL = data.researchToolBaseURL
          this.showTracking = data.showTracking
          this.defaultGamePage = data.defaultGamePage
          this.resendFileChunkSizeKb = data.resendFileChunkSizeKb
          this.checkBenderHealth = data.checkBenderHealth
          this.checkPlayBuilderHealth = data.checkPlayBuilderHealth
        })
      )
      .catch((e) => {})
  }

  @observable lastSchedulePage = '/'

  initialize() {
    if (document) {
      document.title = this.title
    }

    this.titleReaction = reaction(
      () => this.title,
      (title) => {
        if (document) {
          document.title = title
        }
      }
    )

    if (this.isSchedulePage) {
      this.lastSchedulePage = this.url
    }

    this.lastSchedulePageReaction = reaction(
      () => ({ url: this.url, isSchedulePage: this.isSchedulePage }),
      ({ url, isSchedulePage }) => {
        if (isSchedulePage) {
          this.lastSchedulePage = this.url
        }
      }
    )
  }
}
