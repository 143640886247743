import cn from 'classnames'
import { observer } from 'mobx-react'
import { useStore } from '../../../hooks'
import { parseTimeString, validateTimeString } from './utils'
import { toast } from 'react-hot-toast'
import { useMemo, useState } from 'react'
import moment from 'moment'
import uuid from 'uuid'
import Popover from 'react-tiny-popover'
import { DeletePlayEventPopover } from './DeletePlayEventPopover'
import { Positions } from 'shared/constants/Positions'
import { PlayEventTypes } from 'shared/constants/PlayEventTypes'

export const AddEditPlayEventPopover = observer((props) => {
  const store = useStore()

  const isEdit = !!props.playEventId
  const [playEvent, setPlayEvent] = useState({
    playEventType: props.playEventType ?? '',
    positionId: props.positionId ?? '0',
    time: props.displayTime ?? '',
  })
  const handleFormChange = (e) => {
    setPlayEvent({ ...playEvent, [e.target.name]: e.target.value })
  }
  const isValid = useMemo(() => validatePlayEvent(playEvent), [playEvent])
  const [isDeleteOpen, setDeleteOpen] = useState(false)
  const firstEventTimestamp = props.firstEvent?.timeStamp

  async function savePlayEvent(e) {
    e.stopPropagation()

    const payload = getPayload(
      props.playId,
      props.playEventId,
      firstEventTimestamp,
      playEvent
    )

    props.close()
    toast('Saving play event')
    const result = await store.play.savePlayEvent(payload)
    if (result?.success) {
      toast.success('Successfully saved play event')
    } else {
      toast.error('Error saving play event')
    }
  }

  return (
    <div
      className='card popover-card'
      style={{ border: '1px solid whitesmoke', zIndex: 500 }}
    >
      <header className='card-header' style={{ backgroundColor: 'whitesmoke' }}>
        <p className='card-header-title'>
          {isEdit ? 'Edit Play Event' : 'Add Play Event'}
        </p>
        <a
          className='card-header-icon'
          aria-label='close'
          onClick={props.close}
        >
          <span className='icon'>
            <i className='fas fa-times' aria-hidden='true'></i>
          </span>
        </a>
      </header>
      <div className='card-content'>
        <div className='field'>
          <label className='label' htmlFor='playEventType'>
            Event Type
          </label>
          <div className='select' style={{ width: '100%' }}>
            <select
              name='playEventType'
              id='playEventType'
              style={{ width: '100%' }}
              value={playEvent.playEventType}
              onChange={handleFormChange}
            >
              <option value=''></option>
              {PlayEventTypes.map((pet) => (
                <option value={pet.value} key={pet.value}>
                  {pet.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='field'>
          <label className='label' htmlFor='positionId'>
            Position
          </label>
          <div className='select' style={{ width: '100%' }}>
            <select
              name='positionId'
              id='positionId'
              style={{ width: '100%' }}
              value={playEvent.positionId}
              onChange={handleFormChange}
            >
              {Positions.map((p) => (
                <option value={p.value} key={p.value}>
                  {p.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='field'>
          <label className='label' htmlFor='time'>
            Time
          </label>
          <div className='control'>
            <input
              name='time'
              id='time'
              className='input'
              value={playEvent.time}
              placeholder='Time'
              onChange={handleFormChange}
            />
          </div>
        </div>
      </div>
      <footer className='card-footer'>
        {isEdit && (
          <Popover
            isOpen={isDeleteOpen}
            content={
              <DeletePlayEventPopover
                playEventId={props.playEventId}
                playEventType={playEvent.playEventType}
                timeStamp={props.timeStamp}
                close={() => {
                  setDeleteOpen(false)
                  props.close()
                }}
              />
            }
          >
            <a
              className='card-footer-item button is-danger'
              aria-label='delete'
              onClick={() => setDeleteOpen(true)}
            >
              Delete
            </a>
          </Popover>
        )}
        <a
          className='card-footer-item button'
          onClick={props.close}
          aria-label='cancel'
        >
          Cancel
        </a>
        <a
          className={cn('card-footer-item button is-primary', {
            'is-disabled': !isValid,
          })}
          onClick={savePlayEvent}
          aria-label='Save'
        >
          Save
        </a>
      </footer>
    </div>
  )
})

function validatePlayEvent(playEvent) {
  if (playEvent.playEventType === '') return false
  return validateTimeString(playEvent.time)
}

function getPayload(playId, playEventId, firstEventTimestamp, playEvent) {
  const timeInSeconds = parseTimeString(playEvent.time)
  const timestamp = moment
    .utc(firstEventTimestamp)
    .add(timeInSeconds, 's')
    .toISOString()

  return {
    data: {
      PlayEventData: {
        playID: playId,
        playEventType: playEvent.playEventType,
        positionID: playEvent.positionId,
        timestamp: timestamp,
        playEventID: playEventId || uuid(),
      },
    },
  }
}
