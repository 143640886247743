import {
  observable,
  computed,
  action,
  transaction,
  reaction,
  toJS,
  spy,
  extendObservable,
  extras,
} from 'mobx'
import axios, { CancelToken } from 'axios'
import moment from 'moment'

let source = CancelToken.source()
const fetch = (options) =>
  axios(
    Object.assign({}, options, {
      cancelToken: source.token,
    })
  ).then((response) => response.data)
export default class StatusStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable gamePk = null
  @observable statusType = null

  @computed get game() {
    if (this.store.isGamePage) {
      return this.store.game.schedule
    }

    let game = this.store.schedule.gameMap[this.gamePk]

    if (!game) {
      game = this.store.schedule.eventMap[this.gamePk]
    }

    return game || null
  }

  @action trigger(gamePk, statusType) {
    if (!statusType || !gamePk) {
      return this.close()
    }

    if (statusType == this.statusType && gamePk == this.gamePk) {
      return this.close()
    }

    this.statusType = statusType
    this.gamePk = gamePk
  }

  @action close() {
    this.gamePk = null
    this.statusType = null
  }

  @computed get showing() {
    return this.game && this.statusType ? true : false
  }

  initialize() {
    this.pageReaction = reaction(
      () => this.store.page,
      (page) => {
        this.close()
      }
    )
  }
}
