import { isObject, keyBy, transform } from 'lodash'

export function deepOmit(obj, keysToOmit) {
  var keysToOmitIndex = keyBy(
    Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]
  )

  function omitFromObject(obj) {
    return transform(obj, function (result, value, key) {
      if (key in keysToOmitIndex) {
        return
      }
      result[key] = isObject(value) ? omitFromObject(value) : value
    })
  }

  return omitFromObject(obj)
}
