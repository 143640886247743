import { HitDataTable } from 'components/play/HitData'
import { PitchDataTable } from 'components/play/PitchData'
import { inject, observer } from 'mobx-react'
import React from 'react'

export const StatsCard = (props) => {
  let { title = 'Stats' } = props

  return (
    <div className='card mb-4'>
      <header className='card-header' style={{ backgroundColor: 'whitesmoke' }}>
        <p className='card-header-title has-text-centered'>{title}</p>
      </header>
      <div className='card-content'>
        <StatsCardContent {...props} />
      </div>
    </div>
  )
}

export const StatsCardContent = inject('store')(
  observer((props) => {
    let { pitchData = {}, hitData = {} } = props

    return (
      <div>
        <p className='subtitle mb-2'>Pitch Data</p>
        <PitchDataTable pitchData={pitchData} />
        <p className='subtitle mb-2'>Hit Data</p>
        <HitDataTable hitData={hitData} />
      </div>
    )
  })
)
