import cn from 'classnames'
import SkeletonImage from 'components/ui/SkeletonImage'
import SkeletonVideo from 'components/ui/SkeletonVideo'
import { useStore } from 'hooks/useStore'
import { startCase } from 'lodash'
import { observer } from 'mobx-react'
import * as React from 'react'

export function Calibration() {
  return <CalibrationContent />
}

export function CustomCalibration({ assets = [] }) {
  const [activeIndex, setActiveIndex] = React.useState(0)

  const displaySideBySide =
    assets.length <= 2 && assets.every((asset) => asset.vendor === 'trackman')

  return (
    <>
      {displaySideBySide && <SideBySideCalibrationAssets assets={assets} />}
      {!displaySideBySide && assets.length > 1 && (
        <React.Fragment>
          <CalibrationTabs
            assets={assets}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <CalibrationAsset assets={assets} activeIndex={activeIndex} />
        </React.Fragment>
      )}
      {assets.length === 1 && (
        <CalibrationAsset assets={assets} activeIndex={activeIndex} />
      )}
      {assets.length === 0 && <NoContent />}
    </>
  )
}

const CalibrationContent = observer(() => {
  const store = useStore()
  const assets = store.play.calibrationAssets.slice()
  const [activeIndex, setActiveIndex] = React.useState(0)

  const displaySideBySide =
    assets.length <= 2 && assets.every((asset) => asset.vendor === 'trackman')

  return (
    <>
      {displaySideBySide && <SideBySideCalibrationAssets assets={assets} />}
      {!displaySideBySide && assets.length > 1 && (
        <React.Fragment>
          <CalibrationTabs
            assets={assets}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
          <CalibrationAsset assets={assets} activeIndex={activeIndex} />
        </React.Fragment>
      )}
      {assets.length === 1 && (
        <CalibrationAsset assets={assets} activeIndex={activeIndex} />
      )}
      {assets.length === 0 && <NoContent />}
    </>
  )
})

const CalibrationTabs = ({ assets, activeIndex, setActiveIndex }) => {
  return (
    <div className='tabs is-small is-toggle is-fullwidth m-2'>
      <ul>
        {assets.map((asset, i) => {
          const setActive = (e) => setActiveIndex(i)

          return (
            <li
              key={asset.name}
              className={cn({
                'is-active': activeIndex === i,
              })}
              onClick={setActive}
            >
              <a>{startCase(asset.name)}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const CalibrationAsset = ({ activeIndex, assets }) => {
  const asset = assets[activeIndex]
  const { contentType = '' } = asset

  return (
    <div className='card-content'>
      {contentType.startsWith('video') && <SkeletonVideo src={asset.url} />}
      {contentType.startsWith('image') && <SkeletonImage src={asset.url} />}
    </div>
  )
}

const SideBySideCalibrationAssets = ({ assets }) => {
  return (
    <div className='card-content'>
      <div className='columns is-multiline'>
        {assets.map(({ contentType = '', url }) => {
          return (
            <div className='column is-12-tablet is-half-widescreen' key={url}>
              {contentType.startsWith('video') && <SkeletonVideo src={url} />}
              {contentType.startsWith('image') && <SkeletonImage src={url} />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const NoContent = () => {
  return (
    <div className='card-content'>
      <p className='has-text-centered'>
        <b>No Calibration Data</b>
      </p>
    </div>
  )
}
