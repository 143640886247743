import { observer } from 'mobx-react'
import { useStore } from '../../../hooks'

export const DeletePlayEventPopover = observer((props) => {
  const store = useStore()

  async function deletePlayEvent(e) {
    const { playEventId, playEventType, timeStamp } = props
    store.play.deletePlayEvent(playEventId, playEventType, timeStamp)
    props.close(e)
  }

  return (
    <div
      className='card popover-card'
      style={{ border: '1px solid whitesmoke', zIndex: 1000 }}
    >
      <header className='card-header' style={{ backgroundColor: 'whitesmoke' }}>
        <p className='card-header-title'>Confirm Delete Play Event</p>
        <a
          className='card-header-icon'
          aria-label='close'
          onClick={props.close}
        >
          <span className='icon'>
            <i className='fas fa-times' aria-hidden='true'></i>
          </span>
        </a>
      </header>
      <div className='card-content'>
        <div className='card-content has-text-centered'>
          <p>Delete this play event?</p>
        </div>
      </div>
      <footer className='card-footer'>
        <a
          className='card-footer-item button'
          onClick={props.close}
          aria-label='cancel'
        >
          Cancel
        </a>
        <a
          className='card-footer-item button is-primary'
          onClick={deletePlayEvent}
          aria-label='Confirm'
        >
          Confirm
        </a>
      </footer>
    </div>
  )
})
