import React from 'react'
import { inject, observer } from 'mobx-react'
import Select from 'react-select'
import { css } from '@emotion/react'

const OperatorChangeReason = inject('store')(
  observer((props) => {
    if (
      !props.store.edit.play.operatorChangeReasons?.[props.operatorChangeType]
    ) {
      const defaultToReplayReviewChangeReason =
        props.store.edit.play?.type === 'EDIT_REVIEWS' ||
        props.store.edit.play?.reviews?.length > 0

      const defaultOperatorChangeReason = defaultToReplayReviewChangeReason
        ? 4
        : 2

      props.store.edit.updateOperatorChangeReason(
        props.operatorChangeType,
        defaultOperatorChangeReason
      )
    }
    return (
      <div className='field is-horizontal'>
        <div
          className='field-label'
          css={css`
            flex-grow: 2;
            text-align: 'right';
            align-self: 'center';
          `}
        >
          <label className='label'>Change Reason</label>
        </div>
        <div
          className='field-body'
          css={css`
            flex-grow: 3;
          `}
        >
          <div className='field'>
            <div className='control is-expanded'>
              <div className='select is-fullwidth'>
                <Select
                  className='is-small'
                  options={props.store.operatorChangeReason._reasons}
                  value={
                    props.store.edit.play.operatorChangeReasons[
                      props.operatorChangeType
                    ]
                  }
                  labelKey='editReasonDesc'
                  valueKey='editReasonId'
                  onChange={(value) =>
                    props.store.edit.updateOperatorChangeReason(
                      props.operatorChangeType,
                      value
                    )
                  }
                  simpleValue
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })
)
export default OperatorChangeReason
