import Axios from 'axios'
import { NotificationTypes } from 'constants'
import { useStore } from 'hooks'
import { useMutation } from 'react-query'

export function useHawkeyeResend() {
  const store = useStore()
  return useMutation(
    ({ body }) =>
      Axios.post(`/api/games/${store.gamePk}/hawkeyeResend`, { body }).then(
        (response) => response.data
      ),
    {
      onSuccess(data, variables, context) {
        store.notifications.setMessage('Succesfully Resent File')
        store.notifications.trigger(NotificationTypes.GENERIC_SUCCESS, 2)
      },
      onError(error, variables, context) {
        console.info(error.response)
        store.notifications.setMessage('Error Resending File')
        store.notifications.trigger(NotificationTypes.GENERIC_ERROR, 2)
      },
    }
  )
}
