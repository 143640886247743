import axios from 'axios'
import { NotificationTypes } from 'constants'
import { action, observable } from 'mobx'
import { BrokerType } from 'shared/constants'

const fetch = (options) =>
  axios(Object.assign({}, options, {})).then((response) => response.data)

export default class ActionStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @observable loading = false

  @action setLoading(loading) {
    this.loading = loading
  }

  removeStringer(gamePk) {
    if (window.confirm('Are you sure you want to remove the Stringer?')) {
      this.loading = true

      return fetch({
        method: 'post',
        url: '/api/actions/removeStringer',
        data: {
          gamePk,
        },
      })
        .then(() => {
          this.loading = false
          this.store.notifications.trigger(
            NotificationTypes.REMOVE_STRINGER_SUCCESS,
            2
          )
        })
        .catch((e) => {
          console.error(e)
          this.loading = false
          this.store.notifications.trigger(
            NotificationTypes.REMOVE_STRINGER_ERROR,
            2
          )
        })
    }
  }

  removeBoss(gamePk) {
    if (window.confirm('Are you sure you want to remove the Boss?')) {
      this.loading = true

      return fetch({
        method: 'post',
        url: '/api/actions/removeBoss',
        data: {
          gamePk,
        },
      })
        .then(() => {
          this.loading = false
          this.store.notifications.trigger(
            NotificationTypes.REMOVE_BOSS_SUCCESS,
            2
          )
        })
        .catch((e) => {
          console.error(e)
          this.loading = false
          this.store.notifications.trigger(
            NotificationTypes.REMOVE_BOSS_ERROR,
            2
          )
        })
    }
  }

  updateScrub(id, status) {
    this.loading = true

    return fetch({
      method: 'put',
      url: `/api/actions/scrubs/${id}`,
      data: {
        status,
      },
    })
      .then((success) => {
        this.store.notifications.trigger(NotificationTypes.SCRUB_SUCCESS, 2)
        this.store.play.reload()
        this.store.game.refreshBacklog()
      })
      .catch((error) => {
        console.error(error)
        this.store.notifications.trigger(NotificationTypes.SCRUB_ERROR, 2)
      })
      .finally(() => {
        this.loading = false
      })
  }

  createScrub(playId, message) {
    const play = this.store.game.allPlays.find((play) => play.playId == playId)

    if (!play) return console.error('No play found with playId:', playId)

    const { inning, atBatIdx, isPickoff, realPitchNum, pickoffNumber } = play

    const atBatNumber = atBatIdx + 1

    if (!message) {
      message = `Please take a look at Inning: ${inning}, At Bat: ${atBatNumber}`

      if (isPickoff) {
        message += `, Pickoff: ${pickoffNumber}`
      } else {
        message += `, Pitch: ${realPitchNum}`
      }
    }

    this.loading = true

    return fetch({
      method: 'post',
      url: '/api/actions/scrubs',
      data: {
        gamePk: this.store.gamePk,
        playId: playId,
        message,
      },
    })
      .then(() => {
        this.loading = false
        this.store.notifications.trigger(NotificationTypes.SCRUB_SUCCESS, 2)

        this.store.play.reload()
        this.store.game.refreshBacklog()
      })
      .catch((error) => {
        console.error(error)
        this.loading = false
        this.store.notifications.trigger(NotificationTypes.SCRUB_ERROR, 2)
      })
  }

  @action savePlayEventEndTime(payload) {
    this.loading = true

    return fetch({
      method: 'post',
      url: '/api/actions/playEventEndTime',
      data: { gamePk: this.store.gamePk, payload },
    })
      .then(() => {
        // this.store.play.reload()
      })
      .catch((err) => {
        console.warn(err)
      })
      .finally(
        action(() => {
          this.loading = false
        })
      )
  }

  @action resolvePlay(playId) {
    let resolvedPlayMap = {
      ...this.store.game.resolvedPlayMap,
    }

    if (resolvedPlayMap[playId]) {
      delete resolvedPlayMap[playId]
    } else {
      resolvedPlayMap[playId] = true
    }

    this._saveResolvedPlayMap(resolvedPlayMap)
  }

  @action _saveResolvedPlayMap(resolvedPlayMap) {
    this.store.game.resolvedPlayMap = resolvedPlayMap
    this.loading = true

    return fetch({
      method: 'post',
      url: '/api/actions/resolve',
      data: {
        gamePk: this.store.gamePk,
        resolvedPlayMap,
      },
    })
      .then((success) => {
        this.loading = false
        this.store.notifications.trigger(NotificationTypes.RESOLVE_SUCCESS, 2)
      })
      .catch((error) => {
        console.error(error)
        this.loading = false
        this.store.notifications.trigger(NotificationTypes.RESOLVE_ERROR, 2)
      })
  }

  @action resolveAtBat(atBatIdx) {
    let atBat = this.store.game.atBats.find(
      (atBat) => atBat.atBatIdx == atBatIdx && atBat.type == 'result'
    )

    if (!atBat) {
      return this.store.notifications.trigger(
        NotificationTypes.RESOLVE_ERROR,
        2
      )
    }

    let resolvedPlayMap = {
      ...this.store.game.resolvedPlayMap,
    }

    let playIds = atBat.plays
      .filter((play) => play.numberOfMetricErrors && play.playId)
      .map((play) => play.playId)

    if (playIds.every((playId) => resolvedPlayMap[playId])) {
      for (let playId of playIds) {
        delete resolvedPlayMap[playId]
      }
    } else {
      for (let playId of playIds) {
        resolvedPlayMap[playId] = true
      }
    }

    this._saveResolvedPlayMap(resolvedPlayMap)
  }

  async resubmitPlayBuilderErrors(
    venueId,
    text,
    brokerType = BrokerType.DEFAULT
  ) {
    try {
      if (!venueId) {
        throw new Error('missing venueId')
      }

      const json = JSON.parse(text)

      await fetch({
        method: 'post',
        url: '/api/actions/playBuilder',
        data: {
          brokerType,
          venueId,
          json,
        },
      })

      this.store.notifications.setMessage(
        `Successfully resubmitted to playbuilder (brokerType: ${brokerType}) `
      )
      this.store.notifications.trigger(NotificationTypes.GENERIC_SUCCESS, 2)
    } catch (err) {
      this.store.notifications.setMessage(
        `Attempt to resubmit to playbuilder (brokerType: ${brokerType}) (venueId: ${venueId}) failed`
      )
      this.store.notifications.trigger(NotificationTypes.GENERIC_ERROR, 2)
    }
  }

  async bartAction(gamePk, action) {
    try {
      if (!gamePk || !action) {
        throw new Error('missing gamePk or action')
      }

      const res = await fetch({
        method: 'POST',
        url: `/api/actions/bart/${gamePk}/${action}`,
      })

      this.store.notifications.setMessage(
        `Successfully submitted BART action ${action} for game ${gamePk}`
      )
      this.store.notifications.trigger(NotificationTypes.GENERIC_SUCCESS, 2)
    } catch (err) {
      this.store.notifications.setMessage(
        `Attempt to resubmit to BART action ${action} for game ${gamePk} failed`
      )
      this.store.notifications.trigger(NotificationTypes.GENERIC_ERROR, 2)
    }
  }

  initialize() {}
}
