import axios from 'axios'
import { get, pickBy } from 'lodash'
import { action, computed, observable, reaction } from 'mobx'
import moment from 'moment'
import { DateFormat } from 'shared/constants'
import uuid from 'uuid'

const CancelToken = axios.CancelToken
let source = CancelToken.source()

const fetch = (options) =>
  axios(
    Object.assign({}, options, {
      cancelToken: source.token,
    })
  ).then((response) => response.data)

export default class ScheduleStore {
  constructor(store) {
    this.store = store
    this.initialize()
  }

  @computed get date() {
    return this.store.hash.date
      ? moment(this.store.hash.date, DateFormat.URL_SAFE)
      : this.store.date
  }

  @computed get sportId() {
    const orgIds = this.orgIds.split(',')
    return orgIds.includes('all') || this.tab === this.tabs.allScheduled
      ? this.store.config.sports.map((s) => s.id).join(',')
      : orgIds.filter((id) => !!this.store.config.sportMap[id]).join(',')
  }

  @computed get leagueId() {
    const orgIds = this.orgIds.split(',')
    return orgIds.includes('all') || this.tab === this.tabs.allScheduled
      ? ''
      : orgIds.filter((id) => !!this.store.config.leagueMap[id]).join(',')
  }

  @computed get leagueSportId() {
    const orgIds = this.orgIds.split(',')
    return orgIds.includes('all') || this.tab === this.tabs.allScheduled
      ? ''
      : orgIds
          .filter((id) => !!this.store.config.leagueMap[id])
          .map((id) => this.store.config.leagueMap[id]?.sport?.id)
          .filter(Boolean)
          .join(',')
  }

  @computed get orgIds() {
    return this.store.hash.orgIds || '1'
  }

  @observable filter = ''
  @observable games = []
  @observable events = []

  @computed get rows() {
    return this.tab === this.tabs.nonGameEvents
      ? this.events
      : this.filteredGames
  }

  @action set(key, value) {
    this[key] = value
  }

  @computed get tabs() {
    let tabs = {
      connected: 'connected',
      scheduled: 'scheduled',
      allConnected: 'allConnected',
      allScheduled: 'allScheduled',
      nonGameEvents: 'nonGameEvents',
    }

    if (this.store.env === 'qa' || this.store.env === 'local') {
      tabs.simulated = 'simulated'
    }

    return tabs
  }

  @computed get tab() {
    let re = new RegExp(
      /^\/(connected|scheduled|allConnected|allScheduled|simulated|nonGameEvents)/
    )

    let match = this.store.path.match(re)

    return match && match[1] ? match[1] : this.tabs.connected
  }

  @computed get tabHasFilters() {
    return ![
      this.tabs.allConnected,
      this.tabs.simulated,
      this.tabs.allScheduled,
    ].includes(this.tab)
  }

  @computed get gameMap() {
    return this.games.reduce((map, g) => {
      map[g.gamePk] = g
      return map
    }, {})
  }

  @computed get eventMap() {
    return this.events.reduce((map, g) => {
      map[g.id] = g
      return map
    }, {})
  }

  @computed get filteredGames() {
    if (!this.filter) {
      return this.games
    }

    return this.games.filter((game) => {
      let gamePk = game.gamePk
      let awayName = get(game, 'teams.away.team.name')
      let awayAbbrev = get(game, 'teams.away.team.abbreviation')
      let awaySportId = get(game, 'teams.away.team.sport.id')
      let homeName = get(game, 'teams.home.team.name')
      let homeAbbrev = get(game, 'teams.home.team.abbreviation')
      let homeSportId = get(game, 'teams.home.team.sport.id')
      let awayLeague = this.store.config.getSportCode(awaySportId)
      let homeLeague = this.store.config.getSportCode(homeSportId)

      let regex = new RegExp(this.filter, 'i')
      let match = false

      let ary = [
        gamePk,
        awayName,
        awayAbbrev,
        homeName,
        homeAbbrev,
        awayLeague,
        homeLeague,
      ]

      ary.forEach((text) => {
        if (regex.test(text)) {
          match = true
        }
      })

      if (!match) {
        return false
      }

      return true
    })
  }

  @action setData({ games = [], events = [] }) {
    this.games.replace(games)
    this.events.replace(events)
  }

  _timeout
  @action fetch(isPolling = false) {
    if (this.shouldFetch) {
      clearTimeout(this._timeout)
      source.cancel()
      source = CancelToken.source()

      const id = uuid()

      if (!isPolling) {
        this.games.clear()
        this._requests[id] = true
      }

      return fetch({
        url: `/api/schedule`,
        params: this.params,
      })
        .then(this.setData.bind(this))
        .catch((err) => {
          console.error(err)
        })
        .finally(
          action(() => {
            delete this._requests[id]

            clearTimeout(this._timeout)
            this._timeout = setTimeout(() => {
              this.fetch(true)
            }, 10 * 1000)
          })
        )
    }
  }

  @computed get params() {
    const { date, sportId, leagueId, leagueSportId, tab: type } = this

    const params = {
      date: date.format(DateFormat.URL_SAFE),
      sportId,
      leagueId,
      leagueSportId,
      type,
    }

    return pickBy(params, (k, v) => !!v)
  }

  @observable _requests = {}

  @computed get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  @computed get shouldFetch() {
    return (
      this.store.isAuthenticated &&
      this.store.isSchedulePage &&
      (this.params.sportId || this.params.leagueSportId)
    )
  }

  initialize() {
    if (this.shouldFetch) {
      this.fetch()
    }

    this.reaction = reaction(
      () => ({
        params: this.params,
        shouldFetch: this.shouldFetch,
      }),
      ({ shouldFetch }) => {
        if (shouldFetch) {
          this.fetch()
        } else {
          source.cancel()
        }
      }
    )
  }
}
