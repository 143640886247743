import Axios from 'axios'
import { NotificationTypes } from 'constants'
import { useStore } from 'hooks'
import { useMutation } from 'react-query'

export function useSubmitBadVideo() {
  const store = useStore()
  return useMutation(
    ({ body }) =>
      Axios.post(`/api/plays/badVideo`, { body }).then(
        (response) => response.data
      ),
    {
      onSuccess(data, variables, context) {
        store.notifications.setMessage('Succesfully Blocked Bad Video')
        store.notifications.trigger(NotificationTypes.GENERIC_SUCCESS, 2)
        store.game.update(true)
      },
      onError(error, variables, context) {
        console.info(error.response)
        store.notifications.setMessage('Error Blocking Bad Video')
        store.notifications.trigger(NotificationTypes.GENERIC_ERROR, 2)
      },
    }
  )
}

export function useUnblockVideo() {
  const store = useStore()
  return useMutation(
    ({ gameId, playId, videoSourceId }) =>
      Axios.put(
        `/api/plays/unblockVideo?${new URLSearchParams({
          gameId,
          playId,
          videoSourceId,
        })}`
      ).then((response) => response.data),
    {
      onSuccess(data, variables, context) {
        store.notifications.setMessage('Succesfully Unblocked Video')
        store.notifications.trigger(NotificationTypes.GENERIC_SUCCESS, 2)
        store.game.update(true)
      },
      onError(error, variables, context) {
        console.info(error.response)
        store.notifications.setMessage('Error Unblocking Video')
        store.notifications.trigger(NotificationTypes.GENERIC_ERROR, 2)
      },
    }
  )
}
