import { useStore } from 'hooks'
import { observer } from 'mobx-react'
import React from 'react'
import { useQuery } from 'react-query'
import pitchData from './PitchData'

export const OSCardContent = observer(() => {
  const store = useStore()
  const { osCard } = store
  const {
    wildPitchProbabilityCalculated,
    passedBallProbabilityCalculated,
    wildPitchLoading,
    hitProbabilityCalculated,
    errorProbabilityCalculated,
    hitVsErrorLoading,
    shouldShowHitVsError,
  } = osCard

  return (
    <div>
      <table className='table mb-0 is-narrow is-bordered is-fullwidth has-text-centered'>
        <thead>
          <tr>
            <th>Wild Pitch Probability</th>
            <th>Passed Ball Probability</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {wildPitchLoading ? (
              <td>loading...</td>
            ) : (
              <td>{wildPitchProbabilityCalculated}</td>
            )}
            {wildPitchLoading ? (
              <td>loading...</td>
            ) : (
              <td>{passedBallProbabilityCalculated}</td>
            )}
          </tr>
        </tbody>
      </table>

      {shouldShowHitVsError ? (
        <div>
          <br />
          <table className='table mb-0 is-narrow is-bordered is-fullwidth has-text-centered'>
            <thead>
              <tr>
                <th>Hit Probability</th>
                <th>Error Probability</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {hitVsErrorLoading ? (
                  <td>loading...</td>
                ) : (
                  <td>{hitProbabilityCalculated}</td>
                )}
                {hitVsErrorLoading ? (
                  <td>loading...</td>
                ) : (
                  <td>{errorProbabilityCalculated}</td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  )
})
